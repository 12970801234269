<template lang="pug">
v-row.mb-4
    v-col(cols="12" sm="6" md="4" v-if="loading")
        v-skeleton-loader(
            :loading="true"
            type="list-item-avatar"
        )
    v-col(cols="12" sm="6" md="4" v-else)
        Subtitle(text="Organisiert von")
        AvatarCard(
            :name="contactPerson.name"
            :url="contactPerson.url"
            :email="contactPerson.email"
            :phone="contactPerson.phone"
            :avatarUrl="contactPerson.avatar_url"
            maxWidth="100%"
            :avatarType="eventData.contact_type"
        )
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapState } = createNamespacedHelpers("event")
import Subtitle from "../UI/Subtitle.vue"
import AvatarCard from "../UI/AvatarCard.vue"
export default {
  name: "EventOrganization",
  components: { Subtitle, AvatarCard },
  computed: {
    loading() {
      return this.eventLoading || this.attendeesLoading
    },
    contactPerson() {
      return this.eventData.contact
        ? this.eventData.contact
        : this.eventData.creator
    },
    ...mapState({
      attendeesData: (state) => state.loadedEventAttendees.data,
      attendeesLoading: (state) => state.loadedEventAttendees.loading,
      eventData: (state) => state.currentEvent.data,
      eventLoading: (state) => state.currentEvent.loading,
    }),
  },
}
</script>
