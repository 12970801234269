<template lang="pug">
portal.portal(selector="#topic-portal" , v-else)
 div(style="width:100%")
  TopicPage
  Lightbox(:service="lightboxService")
</template>

<script>
import TopicPage from "../TopicPage/index.vue"
import { createNamespacedHelpers } from "vuex"
import { LightboxService } from "features/gallery/lightbox/lightbox.service"
import { provide } from "vue"
const { mapActions: mapMemberActions } = createNamespacedHelpers("member")
const { mapActions: mapGroupActions } = createNamespacedHelpers("group")
const { mapActions: mapTopicActions } = createNamespacedHelpers("topic")

const Lightbox = () => import("features/gallery/lightbox/Lightbox.vue")
export default {
  name: "PortalTopic",
  components: { TopicPage, Lightbox },
  computed: {
    currentGroupIdFromRoute() {
      return this.$route.params.group_id || null
    },
    currentTopicIdFromRoute() {
      return this.$route.params.topic_id || null
    },
  },
  methods: {
    ...mapMemberActions(["getCurrentMember"]),
    ...mapGroupActions(["getGroup"]),
    ...mapTopicActions(["getTopic", "getTopicReactions"]),
  },
  async created() {
    await this.getCurrentMember()
    if (this.currentGroupIdFromRoute) {
      await this.getGroup(this.currentGroupIdFromRoute)
    }
    if (this.currentTopicIdFromRoute) {
      await this.getTopic(this.currentTopicIdFromRoute)
      await this.getTopicReactions(this.currentTopicIdFromRoute)
    }
  },
  setup() {
    const lightboxService = new LightboxService()
    provide("lightboxService", lightboxService)
    return {
      lightboxService,
    }
  },
}
</script>
