import { __awaiter, __generator } from "tslib";
import Axios from "../../initializers/axios";
import { handleErrorResponse } from "helpers/errorHandling";
import { digestApi, eventsApi, rsvpApi } from "../api";
import { PUBLIC_FLAG_URL, EVENT_URL } from "constants/constants";
import "suus-api";
import { parseAxiosError } from "features/common/errors/parseAxiosError";
export default {
    setFormData: function (_a) {
        var commit = _a.commit;
        commit("setCurrEventToFormData");
    },
    setAttendStatus: function (_a, status) {
        var commit = _a.commit;
        commit("SET_ATTEND_STATUS", status);
        commit("checkAttendStatus");
    },
    getEvent: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var currentEvent, event, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (event_id && state.events && state.events[event_id]) {
                            currentEvent = state.events[event_id];
                            commit("SET_EVENT_DATA", currentEvent);
                            commit("checkAttendStatus");
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        commit("SET_EVENT_ERROR", false);
                        commit("SET_EVENT_LOADING", true);
                        return [4 /*yield*/, eventsApi.getEvent(event_id)];
                    case 2:
                        event = (_b.sent()).data;
                        commit("SET_EVENT_DATA", event);
                        commit("ADD_EVENTS", event);
                        commit("checkAttendStatus");
                        if (event.is_public) {
                            commit("ADD_PUBLIC", event_id);
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _b.sent();
                        commit("SET_EVENT_ERROR", error_1);
                        handleErrorResponse(error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        commit("SET_EVENT_LOADING", false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    },
    createEvent: function (_a, request_body) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, Promise, function () {
            var event, error_2, errorMessage;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, eventsApi.createEvent(request_body)];
                    case 1:
                        event = (_b.sent()).data;
                        commit("SET_EVENT_DATA", event);
                        commit("ADD_EVENTS", event);
                        return [2 /*return*/, event];
                    case 2:
                        error_2 = _b.sent();
                        errorMessage = "Failed to create event";
                        if (error_2.response &&
                            error_2.response.data &&
                            error_2.response.data.message) {
                            errorMessage = parseAxiosError(error_2.response);
                        }
                        else {
                            throw error_2;
                        }
                        handleErrorResponse(error_2);
                        throw new Error(errorMessage);
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    updateEvent: function (_a, _b) {
        var state = _a.state, commit = _a.commit;
        var event_id = _b.event_id, request_body = _b.request_body;
        return __awaiter(this, void 0, void 0, function () {
            var event, error_3, errorMessage;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, eventsApi.updateEvent(event_id, request_body)];
                    case 1:
                        event = (_c.sent()).data;
                        commit("SET_EVENT_DATA", event);
                        commit("UPDATE_EVENTS", event);
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _c.sent();
                        errorMessage = "Failed to update event";
                        if (error_3.response &&
                            error_3.response.data.error &&
                            error_3.response.data.message) {
                            errorMessage = parseAxiosError(error_3.response);
                        }
                        else {
                            throw error_3;
                        }
                        handleErrorResponse(error_3);
                        throw new Error(errorMessage);
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    deleteEvent: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var error_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        commit("SET_EVENT_ERROR", false);
                        return [4 /*yield*/, eventsApi.deleteEvent(event_id)];
                    case 1:
                        _b.sent();
                        commit("DELETE_EVENT", event_id);
                        commit("SET_EVENT_DATA", {});
                        commit("setCurrEventToFormData");
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _b.sent();
                        commit("SET_EVENT_ERROR", error_4);
                        handleErrorResponse(error_4);
                        throw error_4;
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    getEventFromDigest: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var currentEvent, event, error_5;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (event_id && state.events && state.events[event_id]) {
                            currentEvent = state.events[event_id];
                            commit("SET_EVENT_DATA", currentEvent);
                            commit("checkAttendStatus");
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        commit("SET_EVENT_ERROR", false);
                        commit("SET_EVENT_LOADING", true);
                        return [4 /*yield*/, digestApi.getDigestEvent(event_id)];
                    case 2:
                        event = (_b.sent()).data;
                        commit("SET_EVENT_DATA", event);
                        commit("ADD_EVENTS", event);
                        commit("checkAttendStatus");
                        return [3 /*break*/, 5];
                    case 3:
                        error_5 = _b.sent();
                        commit("SET_EVENT_ERROR", error_5);
                        handleErrorResponse(error_5);
                        throw error_5;
                    case 4:
                        commit("SET_EVENT_LOADING", false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    },
    getAttendees: function (_a, event_id) {
        var _b, _c, _d, _e;
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var limit, loadedEventAttendees, rsvpResponse, data, declinedRsvpResponse, declinedAttendees, error_6;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        limit = 12;
                        if (event_id && state.attendees && state.attendees[event_id]) {
                            loadedEventAttendees = state.attendees[event_id];
                            commit("SET_ATTENDEES_DATA", loadedEventAttendees);
                            return [2 /*return*/];
                        }
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 4, 5, 6]);
                        commit("SET_ATTENDEES_ERROR", false);
                        commit("SET_ATTENDEES_LOADING", true);
                        return [4 /*yield*/, rsvpApi.getRsvps(event_id, undefined, limit, undefined, "attend")];
                    case 2:
                        rsvpResponse = _f.sent();
                        data = rsvpResponse.data;
                        return [4 /*yield*/, rsvpApi.getRsvps(event_id, undefined, limit, undefined, "decline")];
                    case 3:
                        declinedRsvpResponse = _f.sent();
                        declinedAttendees = declinedRsvpResponse.data;
                        commit("SET_DECLINED_ATTENDEES", declinedAttendees);
                        commit("SET_ATTENDEES_DATA", data);
                        commit("SET_ATTENDEES_ALL_LOADED", rsvpResponse.status === 204 ||
                            data.length < limit ||
                            ((_b = state.loadedEventAttendees.data) === null || _b === void 0 ? void 0 : _b.length) >=
                                ((_c = state.currentEvent.data) === null || _c === void 0 ? void 0 : _c.attends_count));
                        commit("SET_DECLINED_ATTENDEES_ALL_LOADED", declinedRsvpResponse.status === 204 ||
                            declinedAttendees.length < limit ||
                            ((_d = state.currentEventDeclinedAttendees.data) === null || _d === void 0 ? void 0 : _d.length) >=
                                ((_e = state.currentEvent.data) === null || _e === void 0 ? void 0 : _e.declines_count));
                        commit("ADD_ATTENDEES", { event_id: event_id, eventAttendees: data });
                        return [3 /*break*/, 6];
                    case 4:
                        error_6 = _f.sent();
                        commit("SET_ATTENDEES_ERROR", error_6);
                        handleErrorResponse(error_6);
                        throw error_6;
                    case 5:
                        commit("SET_ATTENDEES_LOADING", false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    },
    updateAttendeeCounts: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, eventsApi.getEvent(event_id)];
                    case 1:
                        data = (_b.sent()).data;
                        commit("UPDATE_ATTENDEES_COUNTS", {
                            attends_count: data.attends_count,
                            declines_count: data.declines_count,
                            guests_count: data.guests_count,
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
    getUserAttendStatus: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var currentUserStatus, data, error_7;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (event_id &&
                            state.currentUserStatus &&
                            state.currentUserStati[event_id]) {
                            currentUserStatus = state.currentUserStati[event_id];
                            commit("SET_CURRENT_USER_STATUS", currentUserStatus);
                            commit("checkAttendStatus", currentUserStatus);
                            return [2 /*return*/];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        commit("SET_CURRENT_USER_STATUS_ERROR", false);
                        commit("SET_CURRENT_USER_STATUS_LOADING", true);
                        return [4 /*yield*/, eventsApi.getOwnStatus(event_id)];
                    case 2:
                        data = (_b.sent()).data;
                        commit("SET_CURRENT_USER_STATUS", data.own_status);
                        commit("ADD_CURRENT_USER_STATI", {
                            event_id: event_id,
                            currentUserStatus: data.own_status,
                        });
                        commit("checkAttendStatus", data.own_status);
                        return [3 /*break*/, 5];
                    case 3:
                        error_7 = _b.sent();
                        commit("SET_CURRENT_USER_STATUS_ERROR", error_7);
                        return [3 /*break*/, 5];
                    case 4:
                        commit("SET_CURRENT_USER_STATUS_LOADING", false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    },
    getUserAttendStatusFromDigest: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var currentUserStatus, data, error_8;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (event_id &&
                            state.currentUserStatus &&
                            state.currentUserStati[event_id]) {
                            currentUserStatus = state.currentUserStati[event_id];
                            commit("SET_CURRENT_USER_STATUS", currentUserStatus);
                            commit("checkAttendStatus", currentUserStatus);
                            return [2 /*return*/];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        commit("SET_CURRENT_USER_STATUS_ERROR", false);
                        commit("SET_CURRENT_USER_STATUS_LOADING", true);
                        return [4 /*yield*/, digestApi.getDigestEventOwnStatus(event_id)];
                    case 2:
                        data = (_b.sent()).data;
                        commit("SET_CURRENT_USER_STATUS", data.own_status);
                        commit("ADD_CURRENT_USER_STATI", {
                            event_id: event_id,
                            currentUserStatus: data.own_status,
                        });
                        commit("checkAttendStatus", data.own_status);
                        return [3 /*break*/, 5];
                    case 3:
                        error_8 = _b.sent();
                        commit("SET_CURRENT_USER_STATUS_ERROR", error_8);
                        handleErrorResponse(error_8);
                        return [3 /*break*/, 5];
                    case 4:
                        commit("SET_CURRENT_USER_STATUS_LOADING", false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    },
    addAttend: function (_a, _b) {
        var commit = _a.commit, state = _a.state, dispatch = _a.dispatch;
        var event_id = _b.event_id, guests_count = _b.guests_count;
        return __awaiter(this, void 0, void 0, function () {
            var data, error_9;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, 3, 4]);
                        commit("SET_CURRENT_USER_STATUS_ERROR", false);
                        commit("SET_CURRENT_USER_STATUS_LOADING", true);
                        return [4 /*yield*/, eventsApi.createAttend(event_id, {
                                guests_count: guests_count,
                            })];
                    case 1:
                        data = (_c.sent()).data;
                        commit("SET_CURRENT_USER_STATUS", data.own_status);
                        commit("UPDATE_CURRENT_USER_STATI", {
                            event_id: event_id,
                            currentUserStatus: data.own_status,
                        });
                        commit("checkAttendStatus", data.own_status);
                        dispatch("getAttendees", event_id);
                        return [3 /*break*/, 4];
                    case 2:
                        error_9 = _c.sent();
                        commit("SET_CURRENT_USER_STATUS_ERROR", error_9);
                        handleErrorResponse(error_9);
                        return [3 /*break*/, 4];
                    case 3:
                        commit("SET_CURRENT_USER_STATUS_LOADING", false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    addAttendFromDigest: function (_a, _b) {
        var commit = _a.commit, state = _a.state, dispatch = _a.dispatch;
        var event_id = _b.event_id, guests_count = _b.guests_count;
        return __awaiter(this, void 0, void 0, function () {
            var data, error_10;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, 3, 4]);
                        commit("SET_CURRENT_USER_STATUS_ERROR", false);
                        commit("SET_CURRENT_USER_STATUS_LOADING", true);
                        return [4 /*yield*/, digestApi.createDigestEventAttend(event_id, guests_count)];
                    case 1:
                        data = (_c.sent()).data;
                        commit("SET_CURRENT_USER_STATUS", data.own_status);
                        commit("UPDATE_CURRENT_USER_STATI", {
                            event_id: event_id,
                            currentUserStatus: data.own_status,
                        });
                        commit("checkAttendStatus", data.own_status);
                        return [3 /*break*/, 4];
                    case 2:
                        error_10 = _c.sent();
                        commit("SET_CURRENT_USER_STATUS_ERROR", error_10);
                        handleErrorResponse(error_10);
                        return [3 /*break*/, 4];
                    case 3:
                        commit("SET_CURRENT_USER_STATUS_LOADING", false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    addDecline: function (_a, event_id) {
        var state = _a.state, commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var data, error_11;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        commit("SET_CURRENT_USER_STATUS_ERROR", false);
                        commit("SET_CURRENT_USER_STATUS_LOADING", true);
                        return [4 /*yield*/, eventsApi.createDecline(event_id)];
                    case 1:
                        data = (_b.sent()).data;
                        commit("SET_CURRENT_USER_STATUS", data.own_status);
                        commit("UPDATE_CURRENT_USER_STATI", {
                            event_id: event_id,
                            currentUserStatus: data.own_status,
                        });
                        commit("checkAttendStatus", data.own_status);
                        dispatch("getAttendees", event_id);
                        return [3 /*break*/, 4];
                    case 2:
                        error_11 = _b.sent();
                        commit("SET_CURRENT_USER_STATUS_ERROR", error_11);
                        handleErrorResponse(error_11);
                        return [3 /*break*/, 4];
                    case 3:
                        commit("SET_CURRENT_USER_STATUS_LOADING", false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    addDeclineFromDigest: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var data, error_12;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        commit("SET_CURRENT_USER_STATUS_ERROR", false);
                        commit("SET_CURRENT_USER_STATUS_LOADING", true);
                        return [4 /*yield*/, digestApi.createDigestEventDecline(event_id)];
                    case 1:
                        data = (_b.sent()).data;
                        commit("SET_CURRENT_USER_STATUS", data.own_status);
                        commit("UPDATE_CURRENT_USER_STATI", {
                            event_id: event_id,
                            currentUserStatus: data.own_status,
                        });
                        commit("checkAttendStatus", data.own_status);
                        return [3 /*break*/, 4];
                    case 2:
                        error_12 = _b.sent();
                        commit("SET_CURRENT_USER_STATUS_ERROR", error_12);
                        handleErrorResponse(error_12);
                        return [3 /*break*/, 4];
                    case 3:
                        commit("SET_CURRENT_USER_STATUS_LOADING", false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    addPublic: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var url, data, error_13;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        url = "".concat(EVENT_URL).concat(event_id).concat(PUBLIC_FLAG_URL);
                        return [4 /*yield*/, Axios.post(url)];
                    case 1:
                        data = (_b.sent()).data;
                        commit("UPDATE_EVENT_ATTRIBUTE", { key: "is_public", val: true });
                        commit("UPDATE_EVENTS", state.currentEvent.data);
                        return [3 /*break*/, 3];
                    case 2:
                        error_13 = _b.sent();
                        handleErrorResponse(error_13);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    deletePublic: function (_a, event_id) {
        var state = _a.state, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var url, data, error_14;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        url = "".concat(EVENT_URL).concat(event_id).concat(PUBLIC_FLAG_URL);
                        return [4 /*yield*/, Axios.delete(url)];
                    case 1:
                        data = (_b.sent()).data;
                        commit("UPDATE_EVENT_ATTRIBUTE", { key: "is_public", val: false });
                        commit("UPDATE_EVENTS", state.currentEvent.data);
                        return [3 /*break*/, 3];
                    case 2:
                        error_14 = _b.sent();
                        handleErrorResponse(error_14);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
};
