import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"
import { EVENT_STATUS } from "../../constants/constants"

const state = {
  events: [],
  currentEvent: {
    data: null,
    error: null,
    loading: false,
  },
  eventForm: {},
  attendees: [],
  optimisticAttendeesCount: 0,
  loadedEventAttendees: {
    data: null,
    error: null,
    loading: false,
    allLoaded: false,
  },
  currentEventDeclinedAttendees: {
    data: [],
    allLoaded: false,
  },
  currentUserStati: [],
  currentUserStatus: {
    data: null,
    error: null,
    loading: false,
  },
  activePanels: [0],
  //default: register | stati options: register, registered, deregistered, finished_registration, attend_online_now, event_passed
  attendStatus: EVENT_STATUS.REGISTER,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
