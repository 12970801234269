import { defineComponent, ref, watch } from "vue";
import { mdiClose, mdiGift } from "@mdi/js";
import { useIsBreakpointInNames } from "features/common/vuetify/useVuetify";
import CloseButton from "features/common/ui/CloseButton.vue";
export default defineComponent({
    name: "CloseableDialog",
    components: { CloseButton: CloseButton },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        hash: {
            type: String,
            required: true,
        },
        maxWidth: {
            type: String,
            default: "",
        },
        retainFocus: {
            type: Boolean,
            default: true,
        },
    },
    mounted: function () {
        if (window.location.hash === "#" + this.hash) {
            this.open = true;
            this.$emit("open");
        }
    },
    methods: {
        mdiClose: function () {
            return mdiClose;
        },
        toggleDialog: function (open) {
            if (open) {
                this.$router.push("#" + this.hash);
                this.open = true;
            }
            else {
                var urlWithoutHash = window.location.href.replace(/#.*$/, "");
                window.history.replaceState(null, null, urlWithoutHash);
                this.open = false;
            }
        },
    },
    watch: {
        "$route.hash": function (newHash, oldHash) {
            var hash = "#" + this.hash;
            if (newHash === hash) {
                this.open = true;
            }
            else if (oldHash === hash) {
                this.open = false;
            }
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var isMobile = useIsBreakpointInNames(["xs"]);
        var open = ref(window.location.hash === props.hash);
        watch(function () { return props.value; }, function (newValue) {
            if (open.value !== newValue)
                open.value = newValue;
        }, { immediate: true });
        watch(open, function (newVal) {
            setHashWithoutScrolling(newVal ? props.hash : "");
            emit(newVal ? "open" : "close");
            emit("input", newVal);
        });
        var setHashWithoutScrolling = function (hash) {
            var scrollV = document.scrollingElement.scrollTop;
            window.location.hash = hash;
            // has to be timeout 0, otherwise it doesn't work
            setTimeout(function () {
                document.scrollingElement.scrollTop = scrollV;
            }, 0);
        };
        return {
            mdiGift: mdiGift,
            isMobile: isMobile,
            open: open,
            hash: props.hash,
        };
    },
});
