import { defineComponent } from "vue";
import { EmailStatuses } from "suus-api";
export default defineComponent({
    name: "MemberlistItem",
    props: {
        member: { type: Object, required: true },
        expanded: { type: Boolean, default: false },
    },
    data: function () {
        return {};
    },
    computed: {
        tel_url: function () {
            if (this.member.phone_number) {
                return "tel:" + this.member.phone_number;
            }
            else if (this.member.main_landline) {
                return "tel:" + this.member.main_landline;
            }
            else {
                return null;
            }
        },
        isBounced: function () {
            return this.member.email_status === EmailStatuses.Bounced;
        },
        isAutomaticallyVerified: function () {
            return this.member.email_status === EmailStatuses.AutomaticallyVerified;
        },
        isManuallyVerified: function () {
            return this.member.email_status === EmailStatuses.ManuallyVerified;
        },
    },
});
