// useVuetify.ts
import { computed, getCurrentInstance } from "vue";
/**
 * returns the current vuetify instance
 */
export function useVuetify() {
    var instance = getCurrentInstance();
    if (!instance) {
        throw new Error("useVuetify should be called in setup().");
    }
    return instance.proxy.$vuetify;
}
/**
 * returns a computed property that is the current vuetify breakpoint name
 */
export function useVuetifyBreakpointName() {
    var vuetify = useVuetify();
    return computed(function () { return vuetify.breakpoint.name; });
}
/**
 * @param names - array of vuetify breakpoint names. e.g. ['xs', 'sm', 'md', 'lg', 'xl']
 *
 * returns a computed property that is true if the current breakpoint name is in the array
 */
export function useIsBreakpointInNames(names) {
    var breakpointName = useVuetifyBreakpointName();
    return computed(function () { return names.includes(breakpointName.value); });
}
export function useVuetifyTheme() {
    var vuetify = useVuetify();
    return vuetify.theme.currentTheme;
}
