import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import { EVENT_STATUS } from "constants/constants";
import { createNamespacedHelpers } from "vuex";
var _a = createNamespacedHelpers("event"), mapState = _a.mapState, mapActions = _a.mapActions, mapGetters = _a.mapGetters;
import Digest from "../graphics/Digest.vue";
import Header from "../UI/Header.vue";
import ErrorHero from "../UI/ErrorHero.vue";
import AttendCard from "./AttendCard.vue";
import GuestModal from "./GuestModal.vue";
import "suus-api";
import "vue-i18n";
export default Vue.extend({
    name: "AttendPage",
    components: { Digest: Digest, Header: Header, AttendCard: AttendCard, GuestModal: GuestModal, ErrorHero: ErrorHero },
    computed: __assign(__assign({ titleText: function () {
            return this.$t("components.event.error.titleText");
        }, line1: function () {
            return this.$t("components.event.error.line1");
        }, line2: function () {
            return this.$t("components.event.error.line2");
        }, btnLabel: function () {
            return this.$t("components.event.error.btnLabel");
        }, loading: function () {
            return this.eventLoading || this.currentUserStatusLoading;
        }, headerTextOnAttend: function () {
            var line1 = this.$t("components.attend.headerText.line1");
            if (this.currentUserName)
                line1 = "".concat(line1, " ").concat(this.currentUserName);
            line1 = line1 + ",";
            var line2 = this.$t("components.attend.headerText.line2.REGISTER");
            if (this.cardStatus === EVENT_STATUS.REGISTERED ||
                this.cardStatus === EVENT_STATUS.FINISHED_REGISTRATION) {
                line2 = this.$t("components.attend.headerText.line2.REGISTERED");
            }
            if (this.cardStatus === EVENT_STATUS.DEREGISTERED) {
                line2 = this.$i18n.t("components.attend.headerText.line2.REGISTER");
            }
            if (this.cardStatus === EVENT_STATUS.EVENT_PASSED) {
                line2 = this.$t("components.attend.headerText.line2.EVENT_PASSED");
            }
            if (this.cardStatus === EVENT_STATUS.ATTEND_ONLINE_NOW) {
                line2 = this.$t("components.attend.headerText.line2.ATTEND_ONLINE_NOW");
            }
            return { line1: line1, line2: line2 };
        }, headerTextOnDecline: function () {
            var line1 = "".concat(this.$t("components.attend.headerText.line1"), " ").concat(this.currentUserName, ",");
            var line2 = this.$t("components.attend.headerText.line2.DECLINE");
            if (this.cardStatus === EVENT_STATUS.DEREGISTERED) {
                line2 = this.$i18n.t("components.attend.headerText.line2.DEREGISTERED");
            }
            if (this.cardStatus === EVENT_STATUS.EVENT_PASSED) {
                line2 = this.$t("components.attend.headerText.line2.EVENT_PASSED");
            }
            return { line1: line1, line2: line2 };
        }, headerText: function () {
            return this.isDecline ? this.headerTextOnDecline : this.headerTextOnAttend;
        }, isDecline: function () {
            return this.$route.name === "Decline";
        }, titleLevel: function () {
            return this.$vuetify.breakpoint.name === "xs" ? 4 : 1;
        }, digestWidth: function () {
            return this.$vuetify.breakpoint.name === "xs" ? "30" : "80";
        } }, mapState({
        cardStatus: function (state) { return state.attendStatus; },
        eventData: function (state) { return state.currentEvent.data; },
        eventError: function (state) { return state.currentEvent.error; },
        eventLoading: function (state) { return state.currentEvent.loading; },
        currentUserStatusLoading: function (state) {
            return state.currentUserStatus.loading;
        },
    })), mapGetters(["currentUserName", "hasError"])),
    methods: __assign({ onBtnClick: function () {
            window.open("/", "_self");
        } }, mapActions(["getEventFromDigest", "getUserAttendStatusFromDigest"])),
    created: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.$route.params.event_id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getUserAttendStatusFromDigest(this.$route.params.event_id)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.$route.params.event_id) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getEventFromDigest(this.$route.params.event_id)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
});
