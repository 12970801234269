import { defineComponent } from "vue";
import TextBody from "components/UI/TextBody.vue";
import Hyperlink from "components/UI/Hyperlink.vue";
import TextButton from "components/UI/TextButton.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
export default defineComponent({
    name: "EventHighlightsEmptyContent",
    components: { TextButton: TextButton, Hyperlink: Hyperlink, TextBody: TextBody },
    setup: function () {
        var navigateToPostForm = function () {
            var postForm = document.getElementById("post-form");
            postForm === null || postForm === void 0 ? void 0 : postForm.scrollIntoView({ behavior: "smooth" });
        };
        var t$ = useTranslate("components.event_highlights_tab.empty_content").t$;
        return {
            navigateToPostForm: navigateToPostForm,
            t$: t$,
        };
    },
});
