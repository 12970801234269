var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('EventAttendAlert',{attrs:{"attendStatus":_vm.attendStatus,"guestCount":_vm.guestCount,"guestsAllowed":_vm.guestsAllowed},on:{"toggleAttending":function($event){return _vm.toggleAttending($event)}}}),_vm._v(" "),_c('v-tabs',{attrs:{"background-color":"transparent","centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"attend"},[_vm._v(_vm._s(_vm.t$(".tabs.attendTab")))]),_vm._v(" "),_c('v-tab',{key:"decline"},[_vm._v(_vm._s(_vm.t$(".tabs.declineTab")))])],1),_vm._v(" "),_c('v-tabs-items',{staticStyle:{"background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"attend"},[_c('div',{staticClass:"d-flex justify-center"},[(_vm.isCurrentMemberOrganizerOrAllowed)?_c('AddEditAttendeeDialog',{attrs:{"response":_vm.RsvpReply.Attend,"fetchAttendees":_vm.getSearchFunction,"guestsAllowed":_vm.guestsAllowed,"viewContext":_vm.viewContext,"selected-items":[
            ..._vm.coetanen,
            ..._vm.otherAttendees,
            ..._vm.declinedAttendees,
          ]},on:{"save":function($event){return _vm.handleCreateAttendee($event)}},scopedSlots:_vm._u([{key:"default",fn:function({ click, attrs }){return [_c('Button',{staticClass:"mb-4",attrs:{"btn-label":_vm.t$('components.add_attendee_button.label')},on:{"click":click}})]}}],null,false,3484338605)}):_vm._e()],1),_vm._v(" "),_c('EventAttendeeListSection',{staticClass:"mb-8",attrs:{"attendees":_vm.coetanen,"attendeesLoading":_vm.attendeesLoading,"title":_vm.AttendingCoetanenSubtitle,"guestsAllowed":_vm.guestsAllowed,"is-load-more-available":false,"is-editable":_vm.isCurrentMemberOrganizerOrAllowed},on:{"editAttendee":_vm.handleAttendeeEdit}}),_vm._v(" "),_c('EventAttendeeListSection',{staticClass:"mb-8",attrs:{"attendees":_vm.otherAttendees,"attendees-loading":_vm.attendeesLoading,"title":_vm.AttendingMembersSubtitle,"guestsAllowed":_vm.guestsAllowed,"is-editable":_vm.isCurrentMemberOrganizerOrAllowed,"is-load-more-available":_vm.isMoreRSVPAvailable},on:{"loadMore":_vm.loadMoreRSVP,"editAttendee":_vm.handleAttendeeEdit}})],1),_vm._v(" "),_c('v-tab-item',{key:"decline"},[_c('div',{staticClass:"d-flex justify-center"},[(_vm.isCurrentMemberOrganizerOrAllowed)?_c('AddEditAttendeeDialog',{attrs:{"response":_vm.RsvpReply.Decline,"fetchAttendees":_vm.getSearchFunction,"guestsAllowed":_vm.guestsAllowed,"selected-items":[
            ..._vm.coetanen,
            ..._vm.otherAttendees,
            ..._vm.declinedAttendees,
          ]},on:{"save":function($event){return _vm.handleCreateAttendee($event)}},scopedSlots:_vm._u([{key:"default",fn:function({ click, attrs }){return [_c('Button',{staticClass:"mb-4",attrs:{"btn-label":_vm.t$('components.add_attendee_button.label')},on:{"click":click}})]}}],null,false,3484338605)}):_vm._e()],1),_vm._v(" "),_c('EventAttendeeListSection',{staticClass:"mb-8 mt-2",attrs:{"attendees":_vm.declinedAttendees,"attendees-loading":_vm.attendeesLoading,"title":_vm.DeclinedMemberSubtitle,"guestsAllowed":_vm.guestsAllowed,"is-editable":_vm.isCurrentMemberOrganizerOrAllowed,"is-load-more-available":_vm.isMoreDeclinedRSVPAvailable},on:{"loadMore":_vm.loadMoreDeclinedRSVP,"editAttendee":_vm.handleAttendeeEdit}})],1)],1),_vm._v(" "),(!_vm.otherAttendees)?_c('TextBody',{staticClass:"text-center mb-4",attrs:{"text":_vm.noAttendeesText}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }