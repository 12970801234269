var ConfirmationService = /** @class */ (function () {
    function ConfirmationService() {
    }
    ConfirmationService.prototype.openConfirmation = function (title, text, confirmText, cancelText) {
        if (!this._callback) {
            throw new Error("No callback was registered in ConfirmationService");
        }
        return this._callback(title, text, confirmText, cancelText);
    };
    ConfirmationService.prototype.registerCallback = function (callback) {
        this._callback = callback;
    };
    return ConfirmationService;
}());
export { ConfirmationService };
