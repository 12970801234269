import { __awaiter, __generator } from "tslib";
import "suus-api";
import { defineComponent, ref, watch } from "vue";
import PostListItem from "./PostListItem.vue";
import PostForm from "features/posts/form/PostForm.vue";
import TextButton from "../../components/UI/TextButton.vue";
import TextBody from "../../components/UI/TextBody.vue";
import PostListSkeleton from "./PostListSkeleton.vue";
import "concerns/useContextName";
import OmissionLoader from "features/omission-loader/OmissionLoader.vue";
import "features/omission-loader/use-omission-loader-internal";
import { useOmissionLoader } from "features/omission-loader/use-omission-loader";
import DividerZigZag from "components/UI/DividerZigZag.vue";
import { useThemeColors } from "features/common/theme/useTheme";
export default defineComponent({
    name: "PostList",
    props: {
        totalPostsCount: { type: Number, required: true },
        entityId: { type: Number, required: true },
        canCreatePosts: { type: Boolean, default: false },
        viewContext: { type: Object },
        loadPosts: {
            type: Function,
            required: true,
        },
        performCreatePost: {
            type: Function,
            required: true,
        },
        performUpdatePost: {
            type: Function,
            required: true,
        },
        performDeletePost: {
            type: Function,
            required: true,
        },
        reverse: { type: Boolean, default: false },
    },
    components: {
        DividerZigZag: DividerZigZag,
        OmissionLoader: OmissionLoader,
        PostListItem: PostListItem,
        TextButton: TextButton,
        TextBody: TextBody,
        PostForm: PostForm,
        PostListSkeleton: PostListSkeleton,
    },
    setup: function (props, context) {
        var _this = this;
        var totalCount = ref(props.totalPostsCount);
        var dividerBackgroundColor = useThemeColors().surface;
        var _a = useOmissionLoader(), addedItem = _a.addedItem, updatedItem = _a.updatedItem, removedItem = _a.removedItem;
        watch(function () { return props.totalPostsCount; }, function (newTotalCount) {
            totalCount.value = newTotalCount;
        });
        var increaseTotalCount = function () {
            totalCount.value += 1;
            context.emit("optimistic-posts-count", totalCount.value);
        };
        var decreaseTotalCount = function () {
            totalCount.value -= 1;
            context.emit("optimistic-posts-count", totalCount.value);
        };
        function updatePost(postId, postText) {
            return __awaiter(this, void 0, void 0, function () {
                var updatedPost;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, props.performUpdatePost(props.entityId, postId, postText)];
                        case 1:
                            updatedPost = _a.sent();
                            if (!updatedPost)
                                throw new Error("Der Beitrag konnte nicht aktualisiert werden.");
                            return [2 /*return*/, updatedPost];
                    }
                });
            });
        }
        function onPostUpdated(updatedPost) {
            return __awaiter(this, void 0, void 0, function () {
                var updateFunction;
                return __generator(this, function (_a) {
                    updateFunction = function (oldPost) {
                        oldPost.text = updatedPost.text;
                        oldPost.attachments = updatedPost.attachments;
                        oldPost.event_media_count = updatedPost.event_media_count;
                        oldPost.event_media_preview = updatedPost.event_media_preview;
                        return oldPost;
                    };
                    updatedItem.trigger({
                        id: updatedPost.id,
                        updateFunction: updateFunction,
                    });
                    return [2 /*return*/];
                });
            });
        }
        function deletePostWithOptimisticUpdate(postId) {
            return __awaiter(this, void 0, Promise, function () {
                var isSuccess;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, props.performDeletePost(props.entityId, postId)];
                        case 1:
                            isSuccess = _a.sent();
                            if (isSuccess) {
                                decreaseTotalCount();
                                removedItem.trigger(postId);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        var onPostCreated = function (createdPost) {
            increaseTotalCount();
            addedItem.trigger({ createdItem: createdPost, toLeading: props.reverse });
        };
        var createPost = function (postText) { return __awaiter(_this, void 0, Promise, function () {
            var createdPost;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, props.performCreatePost(props.entityId, postText)];
                    case 1:
                        createdPost = _a.sent();
                        if (!createdPost)
                            throw new Error("Bei der Erstellung des Beitrags ist ein Fehler aufgetreten.");
                        return [2 /*return*/, createdPost];
                }
            });
        }); };
        var deletePost = function (postId) { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, props.performDeletePost(props.entityId, postId)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        return {
            createPost: createPost,
            deletePost: deletePost,
            onPostCreated: onPostCreated,
            updatePost: updatePost,
            onPostUpdated: onPostUpdated,
            deletePostWithOptimisticUpdate: deletePostWithOptimisticUpdate,
            totalCount: totalCount,
            dividerBackgroundColor: dividerBackgroundColor,
        };
    },
});
