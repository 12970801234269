/**
 * Requires following provides:
 *  - loadAttachmentByFrontendId
 *  - cancelUpload
 *  - retryUpload
 */
import { __assign } from "tslib";
import { computed, defineComponent, inject, onBeforeUnmount, ref, } from "vue";
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";
import Button from "components/UI/Button.vue";
import AttachmentUploadTableItem from "features/attachments/AttachmentUploadTableItem.vue";
import "features/attachments/attachment.types";
import AttachmentItemImageMode from "components/Wysiwyg/extensions/attachment/attachment-item/AttachmentItemImageMode.vue";
import { AttachmentFunctions } from "features/attachments/attachment.functions";
import { getUnsupportedMessageForAttachmentImageMode, isMimeTypeSupported, } from "app/modules/common/supported-image-formats";
export default defineComponent({
    name: "AttachmentItemNodeView",
    components: {
        AttachmentItemImageMode: AttachmentItemImageMode,
        AttachmentUploadTableItem: AttachmentUploadTableItem,
        Button: Button,
        NodeViewWrapper: NodeViewWrapper,
    },
    props: nodeViewProps,
    setup: function (props) {
        var editable = ref(true); // FIXME
        var viewWrapper = ref(null);
        var frontendId = computed(function () { return props.node.attrs["data-frontend-id"]; });
        var storedFile = computed(function () { return props.node.attrs["data-stored-file"]; });
        var fileName = computed(function () { return props.node.attrs["data-file-name"]; });
        var name = computed(function () { var _a; return (_a = attachmentUpload === null || attachmentUpload === void 0 ? void 0 : attachmentUpload.value) === null || _a === void 0 ? void 0 : _a.name; });
        var type = computed(function () { var _a; return (_a = attachmentUpload === null || attachmentUpload === void 0 ? void 0 : attachmentUpload.value) === null || _a === void 0 ? void 0 : _a.type; });
        var thumbDataUrl = computed(function () { var _a; return (_a = attachmentUpload === null || attachmentUpload === void 0 ? void 0 : attachmentUpload.value) === null || _a === void 0 ? void 0 : _a.thumbDataUrl; });
        var isUploading = computed(function () {
            var _a, _b;
            return (((_a = attachmentUpload === null || attachmentUpload === void 0 ? void 0 : attachmentUpload.value) === null || _a === void 0 ? void 0 : _a.uploadProgress) !== 100 &&
                ((_b = attachmentUpload === null || attachmentUpload === void 0 ? void 0 : attachmentUpload.value) === null || _b === void 0 ? void 0 : _b.uploadProgress) !== undefined);
        });
        var loadError = ref(undefined);
        var error = computed(function () {
            var _a, _b;
            return (((_a = attachmentUpload === null || attachmentUpload === void 0 ? void 0 : attachmentUpload.value) === null || _a === void 0 ? void 0 : _a.errors) &&
                ((_b = attachmentUpload === null || attachmentUpload === void 0 ? void 0 : attachmentUpload.value) === null || _b === void 0 ? void 0 : _b.errors[0])) ||
                loadError.value;
        });
        var loadAttachmentByFrontendId = inject("loadAttachmentByFrontendId");
        var cancel = inject("cancelUpload");
        var retry = inject("retryUpload");
        var attachmentUpload = loadAttachmentByFrontendId(frontendId.value);
        var inSelection = ref(false);
        // the selected prop only is true if component is clicked, so we need to listen to the text selection range
        var isSelected = function () {
            var selection = props.editor.view.state.selection;
            var startPos = props.getPos();
            var endPos = startPos + props.node.nodeSize;
            inSelection.value = selection.from <= startPos && selection.to >= endPos;
        };
        props.editor.on("selectionUpdate", isSelected);
        onBeforeUnmount(function () { return props.editor.off("selectionUpdate", isSelected); });
        onBeforeUnmount(function () {
            if (!storedFile.value &&
                attachmentUpload.value.uploadProgress !== undefined) {
                cancel(frontendId.value);
            }
        });
        var deleteSelf = function () { return props.deleteNode(); };
        var imageMode = computed(function () { var _a; return (_a = props.node.attrs["data-image-mode"]) !== null && _a !== void 0 ? _a : "attachment"; });
        var imageSize = computed(function () { return Number.parseInt(props.node.attrs["data-image-size"]) || undefined; });
        var fullImage = computed(function () {
            var _a, _b, _c, _d;
            return (_b = (_a = attachmentUpload === null || attachmentUpload === void 0 ? void 0 : attachmentUpload.value) === null || _a === void 0 ? void 0 : _a.fullImageUrl) !== null && _b !== void 0 ? _b : (_d = (_c = attachmentUpload === null || attachmentUpload === void 0 ? void 0 : attachmentUpload.value) === null || _c === void 0 ? void 0 : _c.file) === null || _d === void 0 ? void 0 : _d.dataURL;
        });
        var updateName = function (newName) {
            attachmentUpload.value = __assign(__assign({}, attachmentUpload.value), { name: newName });
            props.updateAttributes({ "data-file-name": newName });
        };
        var updateDisplayMode = function (mode) {
            // Remove data attributes
            return props.updateAttributes({ "data-image-mode": mode });
        };
        var updateSize = function (size) {
            return props.updateAttributes({ "data-image-size": size });
        };
        var isImage = computed(function () {
            if (!attachmentUpload.value)
                return false;
            return AttachmentFunctions.isImage(attachmentUpload.value);
        });
        var isImageSupported = computed(function () {
            var _a, _b, _c;
            if (!attachmentUpload.value)
                return false;
            return isMimeTypeSupported((_b = (_a = attachmentUpload.value.mimeContentType) !== null && _a !== void 0 ? _a : attachmentUpload.value.type) !== null && _b !== void 0 ? _b : (_c = attachmentUpload.value.file) === null || _c === void 0 ? void 0 : _c.type);
        });
        var imageModeBlockedMessage = computed(function () {
            var _a, _b, _c;
            if (isImageSupported.value)
                return undefined;
            return getUnsupportedMessageForAttachmentImageMode((_b = (_a = attachmentUpload.value.mimeContentType) !== null && _a !== void 0 ? _a : attachmentUpload.value.type) !== null && _b !== void 0 ? _b : (_c = attachmentUpload.value.file) === null || _c === void 0 ? void 0 : _c.type);
        });
        var thumbDataUrlIfSupportedImage = computed(function () {
            if (!isImageSupported.value)
                return undefined;
            return thumbDataUrl.value;
        });
        return {
            frontendId: frontendId,
            attachmentUpload: attachmentUpload,
            updateName: updateName,
            storedFile: storedFile,
            fileName: fileName,
            deleteSelf: deleteSelf,
            retry: retry,
            editable: editable,
            name: name,
            type: type,
            thumbDataUrl: thumbDataUrl,
            thumbDataUrlIfSupportedImage: thumbDataUrlIfSupportedImage,
            error: error,
            viewWrapper: viewWrapper,
            imageSize: imageSize,
            fullImage: fullImage,
            updateSize: updateSize,
            updateDisplayMode: updateDisplayMode,
            imageMode: imageMode,
            isUploading: isUploading,
            isImage: isImage,
            isImageSupported: isImageSupported,
            imageModeBlockedMessage: imageModeBlockedMessage,
        };
    },
});
