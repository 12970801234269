import { __assign } from "tslib";
import Vue from "vue";
import { EVENT_STATUS } from "constants/constants";
import { createNamespacedHelpers } from "vuex";
var _a = createNamespacedHelpers("event"), mapState = _a.mapState, mapMutations = _a.mapMutations, mapActions = _a.mapActions, mapGetters = _a.mapGetters;
import Header from "../UI/Header.vue";
import Subtitle from "../UI/Subtitle.vue";
import TextBody from "../UI/TextBody.vue";
import ConfettiBag from "../graphics/ConfettiBag.vue";
import { mdiClose } from "@mdi/js";
import { mdiMinus } from "@mdi/js";
import { mdiPlus } from "@mdi/js";
import "suus-api";
import GuestAmountSelection from "components/Event/GuestAmountSelection.vue";
export default Vue.extend({
    name: "GuestModal",
    props: { guest_count: { type: Number, required: true } },
    components: {
        GuestAmountSelection: GuestAmountSelection,
        Header: Header,
        TextBody: TextBody,
        Subtitle: Subtitle,
        ConfettiBag: ConfettiBag,
    },
    data: function () {
        return {
            showModal: false,
            guests: this.guest_count,
            closeIcon: mdiClose,
            minusIcon: mdiMinus,
            plusIcon: mdiPlus,
            isMobile: this.$vuetify.breakpoint.mobile,
            disableSubmit: false,
            validationPattern: /^[0-9]{1,2}$/,
        };
    },
    methods: __assign({ add: function () {
            this.guests = this.guests + 1;
        }, subtract: function () {
            if (this.guests > 0) {
                this.guests = this.guests - 1;
            }
        }, close: function () {
            this.$emit("close", this.guests);
        }, onSubmit: function () {
            this.close();
            this.setAttendStatus(EVENT_STATUS.FINISHED_REGISTRATION);
            if (this.$route.name === "Event") {
                this.addAttend({
                    event_id: this.currentEvent.id,
                    guests_count: this.guests,
                });
            }
            if (this.$route.name === "Attend") {
                this.addAttendFromDigest({
                    event_id: this.currentEvent.id,
                    guests_count: this.guests,
                });
            }
        } }, mapActions(["setAttendStatus", "addAttend", "addAttendFromDigest"])),
    computed: __assign({ rules: function () {
            var _this = this;
            return {
                required: function (value) {
                    if (value === 0 || !!value) {
                        return true;
                    }
                    return _this.$i18n.t("components.guest_modal.rules.rule1");
                },
                guests: function (value) {
                    if (value > 20) {
                        return _this.$i18n.t("components.guest_modal.rules.rule2");
                    }
                    return (_this.validationPattern.test(value) ||
                        _this.$i18n.t("components.guest_modal.rules.rule3"));
                },
            };
        }, modalContent: function () {
            return {
                headerText: this.$i18n.t("components.guest_modal.headerText"),
                subtitleText: this.$i18n.t("components.guest_modal.subtitleText"),
                bodyText: this.$i18n.t("components.guest_modal.bodyText"),
            };
        }, graphicWidth: function () {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "180";
                default:
                    return "250";
            }
        } }, mapState({
        userStatus: function (state) { return state.currentUserStatus.data; },
        attendStatus: function (state) { return state.attendStatus; },
        currentEvent: function (state) { return state.currentEvent.data; },
    })),
    watch: {
        guests: function (val) {
            if (val === undefined ||
                val === null ||
                val > 20 ||
                !this.validationPattern.test(val.toString())) {
                this.disableSubmit = true;
                return;
            }
            this.disableSubmit = false;
        },
        userStatus: function (val) {
            if (val) {
                if (val.guests === undefined) {
                    this.guests = 0;
                }
                if (val.guests === null) {
                    this.guests = 0;
                }
                else {
                    this.guests = val.guests;
                }
            }
        },
    },
});
