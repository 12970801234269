import TextBody from "../UI/TextBody.vue";
import Subtitle from "../UI/Subtitle.vue";
import Button from "../UI/Button.vue";
import TextButton from "../UI/TextButton.vue";
import { defineComponent } from "vue";
export default defineComponent({
    name: "DialogConfirm",
    components: { TextBody: TextBody, Button: Button, TextButton: TextButton, Subtitle: Subtitle },
    props: {
        value: { type: Boolean, default: false },
        confirmTitle: { type: String, required: true },
        confirmText: { type: String, required: true },
        confirmButtonLabel: { type: String, required: true },
    },
    setup: function (props, context) {
        function handleDialog(val) {
            context.emit("input", val);
        }
        function confirm() {
            handleDialog(false);
            context.emit("confirm");
        }
        return {
            handleDialog: handleDialog,
            confirm: confirm,
        };
    },
});
