import { EVENT_STATUS } from "../../constants/constants"
import moment from "moment"
import Vue from "vue"
export default {
  addActivePanel(state, number) {
    if (!state.activePanels.includes(number)) {
      state.activePanels = [...state.activePanels, number]
    }
  },
  checkAttendStatus(state, _rsvp) {
    const own_status = state.currentUserStatus?.data
    const rsvp = _rsvp || own_status
    const event = state.currentEvent?.data
    if (!rsvp || !event) {
      return (state.attendStatus = EVENT_STATUS.REGISTER)
    }
    const guestsAllowed = event.allow_guests
    const isOnline = event.online
    const daysFromStart = moment().utc().diff(event.start_at, "days")
    const daysFromEnd = moment()
      .utc()
      .diff(event.end_at || event.start_at, "days")

    let isFinished = rsvp.finished_registration
    let isAttending = rsvp.type === "Attend"
    let isDeregistered = rsvp.type === "Decline"

    if (daysFromEnd >= 1) {
      state.attendStatus = EVENT_STATUS.EVENT_PASSED
    } else {
      if (isDeregistered) {
        state.attendStatus = EVENT_STATUS.DEREGISTERED
      } else if (isAttending) {
        if (isOnline && daysFromStart >= -1) {
          state.attendStatus = EVENT_STATUS.ATTEND_ONLINE_NOW
        } else {
          state.attendStatus =
            !guestsAllowed || isFinished
              ? EVENT_STATUS.FINISHED_REGISTRATION
              : EVENT_STATUS.REGISTERED
        }
      }
    }
  },
  SET_ATTEND_STATUS(state, status) {
    state.attendStatus = status
  },
  SET_EVENT_DATA(state, event) {
    state.currentEvent = { ...state.currentEvent, data: event }
  },
  UPDATE_EVENT_ATTRIBUTE(state, { key, val }) {
    let newEventData = Object.assign({}, state.currentEvent.data || {}, {
      [key]: val,
    })
    state.currentEvent = { ...state.currentEvent, data: newEventData }
  },
  SET_EVENT_ERROR(state, error) {
    state.currentEvent = { ...state.currentEvent, error: error }
  },
  SET_EVENT_LOADING(state, loading) {
    state.currentEvent = { ...state.currentEvent, loading: loading }
  },
  ADD_EVENTS(state, event) {
    state.events = [...state.events, { [event.id]: event }]
  },
  UPDATE_EVENTS(state, event) {
    const newEvents = state.events.map((item, index) => {
      if (Object.keys(item)[index] !== event.id) {
        return item
      }
      return { [event.id]: event }
    })
    state.events = newEvents
  },
  DELETE_EVENT(state, event_id) {
    const newEvents = state.events.map((item, index) => {
      if (Object.keys(item)[index] !== event_id) {
        return item
      }
    })
    state.events = newEvents
  },
  SET_DECLINED_ATTENDEES(state, attendees) {
    state.currentEventDeclinedAttendees.data = attendees
  },
  ADD_CURRENT_DECLINED_ATTENDEES(state, attendees) {
    state.currentEventDeclinedAttendees.data = [
      ...state.currentEventDeclinedAttendees.data,
      ...attendees,
    ]
  },
  SET_DECLINED_ATTENDEES_ALL_LOADED(state, allLoaded) {
    state.currentEventDeclinedAttendees.allLoaded = allLoaded
  },
  SET_ATTENDEES_DATA(state, data) {
    state.loadedEventAttendees = {
      ...state.loadedEventAttendees,
      data: data,
    }
    state.optimisticAttendeesCount = 0
  },
  ADD_CURRENT_ATTENDEES(state, data) {
    state.loadedEventAttendees = {
      ...state.loadedEventAttendees,
      data: [...state.loadedEventAttendees.data, ...data],
    }
  },
  ADD_OPTIMISTIC_ATTENDEE(state, attendee) {
    state.loadedEventAttendees = {
      ...state.loadedEventAttendees,
      data: [attendee, ...state.loadedEventAttendees.data],
    }
    state.optimisticAttendeesCount++
  },
  SET_ATTENDEES_ERROR(state, error) {
    state.loadedEventAttendees = {
      ...state.loadedEventAttendees,
      error: error,
    }
  },
  SET_ATTENDEES_LOADING(state, loading) {
    state.loadedEventAttendees = {
      ...state.loadedEventAttendees,
      loading: loading,
    }
  },
  SET_ATTENDEES_ALL_LOADED(state, allLoaded) {
    state.loadedEventAttendees = {
      ...state.loadedEventAttendees,
      allLoaded: allLoaded,
    }
  },
  ADD_ATTENDEES(state, { event_id, eventAttendees }) {
    state.attendees = [...state.attendees, { [event_id]: eventAttendees }]
  },
  UPDATE_ATTENDEES(state, { event_id, eventAttendees }) {
    const newAttendees = state.attendees.map((item, index) => {
      if (Object.keys(item)[index] !== event_id) {
        return item
      }
      return { [event_id]: eventAttendees }
    })
    state.attendees = newAttendees
  },
  UPDATE_ATTENDEES_COUNTS(
    state,
    { declines_count, attends_count, guests_count }
  ) {
    state.currentEvent = {
      ...state.currentEvent,
      data: {
        ...state.currentEvent.data,
        declines_count: declines_count,
        attends_count: attends_count,
        guests_count: guests_count,
      },
    }
  },
  SET_CURRENT_USER_STATUS(state, data) {
    state.currentUserStatus = { ...state.currentUserStatus, data: data }
  },
  SET_CURRENT_USER_STATUS_LOADING(state, loading) {
    state.currentUserStatus = { ...state.currentUserStatus, loading: loading }
  },
  SET_CURRENT_USER_STATUS_ERROR(state, error) {
    state.currentUserStatus = { ...state.currentUserStatus, error: error }
  },
  SET_OPTIMISTIC_ATTACHMENTS_COUNT(state, count) {
    Vue.set(state.currentEvent, "attachmentsOptimisticCount", count)
  },
  UPDATE_CURRENT_USER_STATI(state, { event_id, currentUserStatus }) {
    const newStati = state.currentUserStati.map((item, index) => {
      if (Object.keys(item)[index] !== event_id) {
        return item
      }
      return { [event_id]: currentUserStatus }
    })
    state.currentUserStati = newStati
  },
  ADD_CURRENT_USER_STATI(state, { event_id, currentUserStatus }) {
    state.currentUserStati = [
      ...state.currentUserStati,
      { [event_id]: currentUserStatus },
    ]
  },
  updateEventForm(state, obj) {
    state.eventForm = { ...(state.eventForm || {}), ...obj }
  },
  setCurrEventToFormData(state) {
    state.eventForm = { ...state.currentEvent.data }
  },
}
