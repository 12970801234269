import { computed, defineComponent, inject, ref, watch, } from "vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import "features/events/form/event-form.service";
import Subtitle from "components/UI/Subtitle.vue";
import "features/member/member.service";
import "features/group/group.service";
import "suus-api";
import ContentfulSelect from "components/UI/ContentfulSelect/index.vue";
import TextBody from "components/UI/TextBody.vue";
import GroupSelect from "features/common/forms/form-fields/GroupSelect.vue";
import "components/EventForm/EventForm.vue";
import "features/common/forms/form.service";
import { PanelIdentifier } from "features/events/form/event-form.types";
import { useAccessOptions } from "components/TopicForm/general-fields/use-access-options";
import "components/TopicForm/general-fields/group-access";
export default defineComponent({
    name: "ParticipationCircleFields",
    components: {
        GroupSelect: GroupSelect,
        TextBody: TextBody,
        ContentfulSelect: ContentfulSelect,
        Subtitle: Subtitle,
    },
    setup: function () {
        var t$ = useTranslate("components.event_form").t$;
        var eventFormService = inject("EventFormService");
        var memberService = inject("MemberService");
        var groupService = inject("GroupService");
        var memberScope = memberService.memberScope;
        var selectedScope = ref(null);
        var initOrgLevel = function () {
            var _a, _b;
            var clientScope = memberScope.value.find(function (scope) { return scope.type === "Client"; });
            var currentGroupLevelId = ((_a = group.value) === null || _a === void 0 ? void 0 : _a.level_id) || ((_b = groupService.currentGroup.value) === null || _b === void 0 ? void 0 : _b.level_id);
            var currentLevel = memberScope.value.find(function (scope) {
                return scope.type === "Level" && scope.value.id === currentGroupLevelId;
            });
            var scope = currentLevel || clientScope;
            return scope;
        };
        var orgLevel = computed({
            get: function () {
                if (selectedScope.value) {
                    return selectedScope.value;
                }
                return initOrgLevel();
            },
            set: function (value) {
                selectedScope.value = value;
            },
        });
        var groupRule = {
            field: "group",
            message: t$("components.event_form.validate_group").toString(),
            selector: "event-form__group",
            panelIdentifier: PanelIdentifier.GENERAL,
            action: function (v) { return !!v && !!v.name.length; },
        };
        eventFormService.registerRule(groupRule);
        eventFormService.registerProperty({
            name: "group",
        });
        var group = computed({
            get: function () {
                return eventFormService.getFormValue("group").value;
            },
            set: function (value) {
                eventFormService.setFormValue("group", value);
                eventFormService.validate();
            },
        });
        watch(function () { return groupService.currentGroup.value; }, function (newGroup) {
            if (!group.value && newGroup) {
                group.value = newGroup;
            }
        }, { immediate: true });
        var attendOptions = useAccessOptions(group, memberService.groupMemberships, computed(function () { return eventFormService.getFormValue("nonmember_access").value; }), function (access) {
            eventFormService.setFormValue("nonmember_access", access);
        });
        watch(attendOptions, function (newOptions) {
            if (newOptions.length === 1 &&
                memberAccess.value !== newOptions[0].type) {
                memberAccess.value = newOptions[0].type;
            }
        });
        eventFormService.registerProperty({
            name: "nonmember_access",
        });
        var memberAccess = computed({
            get: function () {
                return eventFormService.getFormValue("nonmember_access").value;
            },
            set: function (value) {
                eventFormService.setFormValue("nonmember_access", value);
            },
        });
        eventFormService.registerProperty({
            name: "allow_guests",
        });
        var allowGuests = computed({
            get: function () {
                var _a;
                return (_a = eventFormService.getFormValue("allow_guests").value) !== null && _a !== void 0 ? _a : false;
            },
            set: function (value) {
                eventFormService.setFormValue("allow_guests", value);
            },
        });
        var isGroupPage = computed(function () {
            var _a;
            return !!((_a = groupService.currentGroup.value) === null || _a === void 0 ? void 0 : _a.id);
        });
        return {
            t$: t$,
            eventFormService: eventFormService,
            memberScope: memberScope,
            orgLevel: orgLevel,
            group: group,
            attendOptions: attendOptions,
            memberAccess: memberAccess,
            allowGuests: allowGuests,
            isGroupPage: isGroupPage,
            isEdit: eventFormService.isEdit,
        };
    },
});
