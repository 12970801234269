import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, inject, ref, watch } from "vue";
import Subtitle from "components/UI/Subtitle.vue";
import Hyperlink from "components/UI/Hyperlink.vue";
import "features/member/member.service";
import { mdiChevronRight } from "@mdi/js";
import "suus-api";
import FieldLabel from "components/UI/FieldLabel.vue";
import GroupMemberSingleSelect from "components/GroupMemberSingleSelect.vue";
import { groupApi } from "store/api";
export default defineComponent({
    name: "GroupSelect",
    components: { GroupMemberSingleSelect: GroupMemberSingleSelect, FieldLabel: FieldLabel, Hyperlink: Hyperlink, Subtitle: Subtitle },
    props: {
        orgLevel: {
            type: Object,
            required: false,
            default: null,
        },
        orgLevelOptions: {
            type: Array,
            required: false,
            default: function () { return []; },
        },
        orgLevelLabel: {
            type: String,
            required: false,
            default: "",
        },
        group: {
            type: Object,
            required: false,
            default: null,
        },
        groupLabel: {
            type: String,
            required: false,
            default: "",
        },
        isEdit: {
            type: Boolean,
            required: false,
            default: false,
        },
        isGroupPage: {
            type: Boolean,
            required: false,
            default: false,
        },
        inputGroupValidation: { type: Array, default: function () { return []; } },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var showLevelField = ref(false);
        var showGroupField = ref(!props.isGroupPage || !props.group);
        var memberService = inject("MemberService", undefined);
        watch(function () { return props.isGroupPage; }, function () {
            showGroupField.value = !props.isGroupPage;
        });
        var showChangeLevelButton = computed(function () {
            return props.orgLevelOptions.length >= 1 &&
                !showLevelField.value &&
                !props.isEdit;
        });
        function toggleShowLevelField() {
            showLevelField.value = !showLevelField.value;
        }
        function emitLevel(orgLevel) {
            emit("orgLevelChange", orgLevel);
        }
        var showChangeGroupButton = computed(function () { return !showGroupField.value; });
        function toggleShowGroupField() {
            emit("toggleShowGroupField");
            showGroupField.value = !showGroupField.value;
        }
        var selectedClientId = ref(null);
        var selectedLevelId = ref(null);
        function getClientIdFromInputLevelOrClient(inputLevelOrClient) {
            var _a;
            return (inputLevelOrClient === null || inputLevelOrClient === void 0 ? void 0 : inputLevelOrClient.type) === "Client"
                ? (_a = inputLevelOrClient.value) === null || _a === void 0 ? void 0 : _a.id
                : null;
        }
        function getLevelIdFromInputLevelOrClient(inputLevelOrClient) {
            var _a;
            return (inputLevelOrClient === null || inputLevelOrClient === void 0 ? void 0 : inputLevelOrClient.type) === "Level"
                ? (_a = inputLevelOrClient.value) === null || _a === void 0 ? void 0 : _a.id
                : null;
        }
        function getClientIdFromFromGroup(group) {
            return group.client_id;
        }
        function getLevelIdFromFromGroup(group) {
            return group.level_id;
        }
        var groupSearchString = ref(null);
        function handleGroupSearch(val) {
            groupSearchString.value = val;
        }
        watch(props, function (newVal, oldVal) {
            if (newVal.group) {
                selectedClientId.value = getClientIdFromFromGroup(newVal.group);
                selectedLevelId.value = getLevelIdFromFromGroup(newVal.group);
            }
            else {
                selectedClientId.value = getClientIdFromInputLevelOrClient(newVal.orgLevel);
                selectedLevelId.value = getLevelIdFromInputLevelOrClient(newVal.orgLevel);
            }
            emit("updateLevelSelection", {
                selectedLevelId: selectedLevelId.value,
                selectedClientId: selectedClientId.value,
            });
        }, { immediate: true });
        var getGroupsParams = computed(function () {
            return {
                clientId: selectedClientId.value,
                levelId: selectedLevelId.value,
                hasForum: true,
                groupSearchString: groupSearchString.value,
            };
        });
        var emitGroupChange = function (group) {
            emit("groupChange", group);
        };
        function getGroups(_a) {
            var _b, _c, _d;
            var levelId = _a.levelId, clientId = _a.clientId, hasForum = _a.hasForum, groupSearchString = _a.groupSearchString;
            return __awaiter(this, void 0, void 0, function () {
                var memberId, data;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            memberId = (_d = (_c = (_b = memberService === null || memberService === void 0 ? void 0 : memberService.currentMember) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.personal_record) === null || _d === void 0 ? void 0 : _d.id;
                            return [4 /*yield*/, groupApi.getGroups(groupSearchString !== null && groupSearchString !== void 0 ? groupSearchString : undefined, levelId !== null && levelId !== void 0 ? levelId : undefined, clientId !== null && clientId !== void 0 ? clientId : undefined, undefined, memberId !== null && memberId !== void 0 ? memberId : undefined, undefined, undefined, undefined, undefined, hasForum !== null && hasForum !== void 0 ? hasForum : false)];
                        case 1:
                            data = _e.sent();
                            return [2 /*return*/, data.data.results];
                    }
                });
            });
        }
        //when user selects new inputLevelOrClient the Group input is resetted
        watch(function () { return props.orgLevel; }, function (newVal, oldVal) {
            var _a, _b;
            if (((_a = oldVal === null || oldVal === void 0 ? void 0 : oldVal.value) === null || _a === void 0 ? void 0 : _a.id) && ((_b = newVal === null || newVal === void 0 ? void 0 : newVal.value) === null || _b === void 0 ? void 0 : _b.id) !== oldVal.value.id) {
                emitGroupChange(null);
            }
        });
        return {
            showLevelField: showLevelField,
            showGroupField: showGroupField,
            showChangeLevelButton: showChangeLevelButton,
            toggleShowLevelField: toggleShowLevelField,
            emitLevel: emitLevel,
            showChangeGroupButton: showChangeGroupButton,
            toggleShowGroupField: toggleShowGroupField,
            getGroupsParams: getGroupsParams,
            emitGroupChange: emitGroupChange,
            handleGroupSearch: handleGroupSearch,
            getGroups: getGroups,
            chevron: mdiChevronRight,
        };
    },
});
