export default {
  loading: (state) => {
    return !!(
      state.currentEvent.loading ||
      state.currentUserStatus.loading ||
      state.loadedEventAttendees.loading
    )
  },
  eventContact: (state) => {
    if (state.currentEvent.data && state.currentEvent.data.contact) {
      return {
        name: state.currentEvent.data.contact.name || null,
        url: state.currentEvent.data.contact.url || null,
      }
    }
    if (state.currentEvent.data && state.currentEvent.data.creator) {
      return {
        name: state.currentEvent.data.creator.name || null,
        url: state.currentEvent.data.creator.url || null,
      }
    }
    return null
  },
  currentAttendeesCount: (state) => {
    return state.loadedEventAttendees.data?.length
  },
  loadedDeclinesCount: (state) => {
    return state.currentEventDeclinedAttendees.data?.length
  },
  optimisticAttendeesCount: (state) => {
    return state.optimisticAttendeesCount
  },
  isEventPublic: (state) => {
    return state.currentEvent.data && state.currentEvent.data.is_public
  },
  eventOrganizer: (state) => {
    return state.currentEvent.data?.contact ?? state.currentEvent.data?.creator
  },
  eventCircleOfPartcipants: (state) => {
    if (state.currentEvent.data && state.currentEvent.data.group) {
      return {
        name: state.currentEvent.data.group.name,
        url: state.currentEvent.data.group.url,
      }
    }
    return null
  },
  guestsAllowed: (state) => {
    return state.currentEvent.data && state.currentEvent.data.allow_guests
  },
  hasAttendees: (state) => {
    return state.currentEvent?.data?.attends_count > 0
  },
  currentUserAttends: (state, getters) => {
    if (getters.hasAttendees && getters.currentUserId) {
      return getters.coetanen?.find(
        (attendee) => attendee.id === getters.currentUserId
      )
    }
    return null
  },
  currentUserIsFirstAttendee: (state, getters) => {
    return !!(
      getters.currentUserAttends &&
      getters.coetanenCount === 1 &&
      getters.otherAttendeesCount === 0
    )
  },
  declinedAttendees: (state) => {
    return state.currentEventDeclinedAttendees.data
  },
  coetanen: (state, getters) => {
    if (getters.hasAttendees) {
      return state.loadedEventAttendees.data?.filter(
        (attendee) => attendee.is_coetan
      )
    }
    return []
  },
  loadedCoetanenCount: (state, getters) => {
    if (getters.hasAttendees && getters.coetanen) {
      return getters.coetanen.length
    }
    return 0
  },
  otherAttendees: (state, getters) => {
    if (getters.hasAttendees) {
      return state.loadedEventAttendees.data?.filter(
        (attendee) =>
          !attendee.is_coetan && attendee.id != getters.currentUserId
      )
    }
    return []
  },
  otherAttendeesCount: (state, getters) => {
    if (getters.hasAttendees && getters.otherAttendees) {
      return (
        getters.totalAttendeesCount -
        getters.loadedCoetanenCount -
        (getters.currentUserId ? 1 : 0)
      )
    }
    return 0
  },
  totalGuestsCount: (state) => {
    return state.currentEvent?.data?.guests_count ?? 0
  },
  totalAttendeesAndGuests: (state, getters) => {
    return getters.hasAttendees
      ? state.currentEvent.data.attends_count +
          state.currentEvent.data.guests_count +
          state.optimisticAttendeesCount
      : 0
  },
  totalAttendeesCount: (state, getters) => {
    return getters.hasAttendees
      ? state.currentEvent.data.attends_count + state.optimisticAttendeesCount
      : 0
  },
  totalDeclinedAttendeesCount: (state) => {
    return state.currentEvent.data.declines_count
  },
  currentUserName: (state) => {
    return state.currentUserStatus.data
      ? state.currentUserStatus.data.name
      : null
  },
  currentUserId: (state) => {
    return state.currentUserStatus.data && state.currentUserStatus.data.id
      ? state.currentUserStatus.data.id
      : null
  },
  currentClientId: (state) => {
    return state.currentEvent.data.client_id
  },
  currentLevelId: (state) => {
    return state.currentEvent.data.level_id
  },
  currentGroupId: (state) => {
    return state.currentEvent.data.group_id
  },
  currentEvent: (state) => {
    return state.currentEvent.data
  },
  currentEventAttachmentsOptimisticCount: (state, getters) => {
    return (
      state.currentEvent.attachmentsOptimisticCount ??
      getters.currentEvent?.attachments?.length ??
      0
    )
  },
  isCurrentUserOrganizer: (state, getters) => (currentMember) => {
    const currentContact = getters.eventOrganizer
    const isOrganizerMember = currentContact.first_name != undefined
    if (isOrganizerMember) {
      return currentMember.personal_record.id === currentContact.id
    } else {
      return currentMember.group_memberships.includes(currentContact.id)
    }
  },
  hasError: (state) => {
    return (
      state.currentUserStatus.error ||
      (state.currentEvent.error && state.currentEvent.error.code !== 422) ||
      state.loadedEventAttendees.error
    )
  },
  isMoreRSVPsAvailable: (state) => {
    return state.loadedEventAttendees.allLoaded === false
  },
  isMoreDeclinedRSVPsAvailable: (state) => {
    return state.currentEventDeclinedAttendees.allLoaded === false
  },
}
