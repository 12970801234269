import { __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
export default defineComponent({
    name: "InfiniteScrollLoader",
    props: {
        loading: {
            type: Boolean,
        },
        allLoaded: {
            type: Boolean,
        },
    },
    setup: function (props, context) {
        var _this = this;
        var scrollContainer = ref(null);
        var emitLoad = function () {
            if (props.loading || props.allLoaded) {
                return;
            }
            context.emit("load");
        };
        watch(function () { return props.loading; }, function (loading) {
            if (!loading && handleScroll) {
                setTimeout(function () { return handleScroll(); }, 300);
            }
        }, { immediate: true });
        var handleScroll = function () { return __awaiter(_this, void 0, void 0, function () {
            var container;
            return __generator(this, function (_a) {
                container = scrollContainer.value;
                if (container.scrollTop + container.clientHeight >=
                    container.scrollHeight - 200) {
                    emitLoad();
                }
                return [2 /*return*/];
            });
        }); };
        onMounted(function () {
            scrollContainer.value.addEventListener("scroll", handleScroll);
        });
        // Remove scroll event listener when the component is unmounted
        onUnmounted(function () {
            var _a;
            (_a = scrollContainer.value) === null || _a === void 0 ? void 0 : _a.removeEventListener("scroll", handleScroll);
        });
        return {
            scrollContainer: scrollContainer,
        };
    },
});
