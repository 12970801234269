import { customRef } from "vue";
export function useEventRef(value) {
    var internalValue = value;
    var _trigger;
    var trigger = function (payload) {
        if (!_trigger) {
            console.warn("EventRef is not initialized yet.");
        }
        else {
            internalValue = payload;
            _trigger();
        }
    };
    var wrap = function () {
        return {
            ref: eventRef,
            trigger: trigger,
        };
    };
    var _customRef = customRef(function (track, trigger) {
        _trigger = trigger;
        return {
            get: function () {
                track();
                return internalValue;
            },
            set: function (newValue) {
                console.warn("EventRef is not supposed to be set. Readonly.");
            },
        };
    });
    var eventRef = Object.assign(_customRef, { trigger: trigger, wrap: wrap });
    return eventRef;
}
