import { defineComponent, ref, watch } from "vue";
import "suus-api";
import CommentForm from "components/Comment/CommentForm.vue";
import "concerns/useContextName";
import useCreatePostComment from "concerns/useCreatePostComment";
import CommentList from "components/Comment/CommentList.vue";
import useUpdatePostComment from "concerns/useUpdatePostComment";
import useDeleteComment from "concerns/useDeleteComment";
export default defineComponent({
    name: "CommentSection",
    components: { CommentList: CommentList, CommentForm: CommentForm },
    props: {
        initialComments: { type: Array, default: function () { return []; } },
        commentFormOpen: { type: Boolean, default: false },
        canCreateComment: { type: Boolean, default: false },
        viewContext: { type: Object },
        postId: { type: Number, required: true },
    },
    setup: function (props, context) {
        var commentText = ref("");
        var commentList = ref(props.initialComments);
        watch(function () { return props.initialComments; }, function (newVal) {
            commentList.value = newVal;
        });
        var totalLoaded = ref(0);
        var updateTotalLoaded = function (newVal) {
            totalLoaded.value = newVal;
        };
        var closeCommentForm = function () {
            context.emit("changeCommentFormVisibility", false);
        };
        var openCommentForm = function () {
            context.emit("changeCommentFormVisibility", true);
        };
        var onCommentCreated = ref(null);
        var saveCommentCreatedCallback = function (cb) {
            onCommentCreated.value = cb;
        };
        function createComment(val) {
            closeCommentForm();
            var _a = useCreatePostComment({
                postId: props.postId.toString(),
                text: val,
            }), createdComment = _a.createdComment, error = _a.error;
            watch(createdComment, function () {
                if (onCommentCreated.value) {
                    onCommentCreated.value(createdComment.value);
                }
            });
            watch(error, function (err) {
                openCommentForm();
                commentText.value = val;
            });
        }
        function updateComment(comment) {
            return new Promise(function (resolve, reject) {
                var _a = useUpdatePostComment(comment), updatedComment = _a.updatedComment, error = _a.error;
                watch(updatedComment, function (newVal) {
                    resolve(newVal);
                });
                watch(error, function (err) {
                    reject(err);
                });
            });
        }
        function deleteComment(comment) {
            return new Promise(function (resolve, reject) {
                var _a = useDeleteComment(comment.id.toString()), isSuccess = _a.isSuccess, error = _a.error;
                watch(isSuccess, function (err) {
                    resolve(comment.id);
                });
                watch(error, function (err) {
                    reject(err);
                });
            });
        }
        return {
            commentText: commentText,
            createComment: createComment,
            saveCommentCreatedCallback: saveCommentCreatedCallback,
            commentList: commentList,
            updateComment: updateComment,
            deleteComment: deleteComment,
            closeCommentForm: closeCommentForm,
            totalLoaded: totalLoaded,
            updateTotalLoaded: updateTotalLoaded,
        };
    },
});
