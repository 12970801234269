import { __awaiter, __generator, __spreadArray } from "tslib";
import { computed, defineComponent, ref, watch } from "vue";
import "suus-api";
import { clientApi, customAttributeDefinitionsApi } from "store/api";
import "axios";
import useTranslate from "features/common/translate/concerns/useTranslate";
import Button from "components/UI/Button.vue";
import Header from "components/UI/Header.vue";
import CloseableDialog from "components/UI/CloseableDialog.vue";
export default defineComponent({
    name: "CustomAttributeUpdater",
    components: { CloseableDialog: CloseableDialog, Header: Header, Button: Button },
    props: {
        client_id: {
            type: Number,
            required: true,
        },
    },
    setup: function (props) {
        var _this = this;
        var client = ref(null);
        var selectedLevel = ref(null);
        var file = ref(null);
        var customAttribute = ref(null);
        var t$ = useTranslate("components.custom_attribute_batch_updater").t$;
        clientApi
            .getClient(props.client_id.toString())
            .then(function (response) {
            client.value = response.data;
        })
            .catch(function (error) {
            console.error(error);
        });
        // load CustomAttributeDefinitions for client from API based on current select client
        var customAttributes = ref([]);
        watch([client, selectedLevel], function (_a) {
            var client = _a[0], selectedLevel = _a[1];
            return __awaiter(_this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!client) return [3 /*break*/, 2];
                            return [4 /*yield*/, customAttributeDefinitionsApi.getCustomAttributeDefinitions((selectedLevel === null || selectedLevel === void 0 ? void 0 : selectedLevel.id) ? null : client.id.toString(), (selectedLevel === null || selectedLevel === void 0 ? void 0 : selectedLevel.id) ? selectedLevel.id.toString() : null)];
                        case 1:
                            response = _b.sent();
                            customAttributes.value = response.data;
                            return [3 /*break*/, 3];
                        case 2:
                            customAttributes.value = [];
                            _b.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        });
        var levels = computed(function () {
            var _a;
            if (!client.value) {
                return [];
            }
            return __spreadArray([
                {
                    id: null,
                    name: client.value.name,
                    url: null,
                    description: "",
                    available_abilities: [],
                    unavailable_abilities: [],
                    has_drive: false,
                }
            ], (((_a = client.value) === null || _a === void 0 ? void 0 : _a.levels) || []), true);
        });
        var message = ref("");
        var loading = ref(false);
        var successCount = ref(0);
        var errors = ref([]);
        var uploadFile = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, data, error_1, errData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        loading.value = true;
                        message.value = "";
                        successCount.value = 0;
                        errors.value = [];
                        return [4 /*yield*/, customAttributeDefinitionsApi.batchUpdateCustomAttributeValues(customAttribute.value.id.toString(), file.value)];
                    case 1:
                        response = _a.sent();
                        if ("data" in response) {
                            data = response.data;
                            errors.value = data.records_not_updated;
                            successCount.value = data.updated_records_count;
                        }
                        else {
                            message.value =
                                "Es ist ein Fehler aufgetreten (" + response.statusText + ")";
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        // Check type if an axios error
                        if (error_1.isAxiosError) {
                            errData = error_1.response
                                .data;
                            message.value = "Server-Fehler beim Aktualisieren der Eintr\u00E4ge: ".concat(errData.error, " (").concat(errData.code, "): ").concat(errData.message);
                            console.warn(error_1);
                        }
                        else {
                            message.value = "Unspezifzierter Fehler beim Aktualisieren der Eintr\u00E4ge: ".concat(error_1);
                            console.error(error_1);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        loading.value = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return {
            client: client,
            selectedLevel: selectedLevel,
            levels: levels,
            customAttributes: customAttributes,
            customAttribute: customAttribute,
            file: file,
            uploadFile: uploadFile,
            message: message,
            loading: loading,
            successCount: successCount,
            errors: errors,
            t$: t$,
        };
    },
    methods: {
        csvFileRules: function () {
            return [
                function (v) { return !!v || "Eine Datei muss ausgewählt werden."; },
                function (v) {
                    return (v === null || v === void 0 ? void 0 : v.type) !== "text/csv" || "Die Datei muss eine CSV-Datei sein.";
                },
                function (v) { return (v === null || v === void 0 ? void 0 : v.size) < 1000000 || "Die Datei ist größer als 1MB."; },
            ];
        },
    },
});
