import { computed, defineComponent, ref } from "vue";
import GalleryThumbnailActionButton from "./GalleryThumbnailActionButton.vue";
import GalleryThumbnailActionBar from "features/gallery/thumbnail/GalleryThumbnailActionBar.vue";
import GalleryThumbnailOverlay from "features/gallery/thumbnail/GalleryThumbnailOverlay.vue";
import GalleryThumbnailErrorOverlay from "features/gallery/thumbnail/GalleryThumbnailErrorOverlay.vue";
import GalleryThumbnailLoadingOverlay from "features/gallery/thumbnail/GalleryThumbnailLoadingOverlay.vue";
import "features/common/vuetify/useVuetify";
import TextBody from "components/UI/TextBody.vue";
export default defineComponent({
    name: "GalleryThumbnail",
    components: {
        TextBody: TextBody,
        GalleryThumbnailLoadingOverlay: GalleryThumbnailLoadingOverlay,
        GalleryThumbnailErrorOverlay: GalleryThumbnailErrorOverlay,
        GalleryThumbnailOverlay: GalleryThumbnailOverlay,
        GalleryThumbnailActionBar: GalleryThumbnailActionBar,
        GalleryThumbnailActionButton: GalleryThumbnailActionButton,
    },
    props: {
        image: {
            type: String,
        },
        alt: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Object,
        },
        actionBar: {
            type: Array,
            default: function () { return []; },
        },
        topLeftAction: {
            type: Object,
        },
        topRightAction: {
            type: Object,
        },
        bottomLeftAction: {
            type: Object,
        },
        size: {
            type: Number,
            default: 128,
        },
    },
    computed: {
        thumbnailStyles: function () {
            var styles = {
                backgroundImage: this.image
                    ? "url(\"".concat(encodeURI(this.image), "\")")
                    : "none",
                backgroundColor: "#f2f2f2",
                aspectRatio: "1 / 1",
                backgroundSize: "cover",
            };
            if (this.size > 0) {
                styles.width = this.size - 8 + "px";
                styles.height = this.size - 8 + "px";
            }
            return styles;
        },
    },
    setup: function (props, context) {
        if (props.bottomLeftAction &&
            props.actionBar &&
            props.actionBar.length > 0) {
            throw new Error("Cannot have both bottomLeftAction and actionBar");
        }
        var thumbnailContainer = ref(null);
        var showActions = computed(function () {
            return !props.loading && !props.error;
        });
        return {
            showActions: showActions,
            thumbnailContainer: thumbnailContainer,
        };
    },
});
