import Vue from "vue";
import { mdiClockOutline } from "@mdi/js";
import THEME from "../../constants/theme";
import moment from "moment";
var fontFamily = THEME.typography.fontFamily;
moment.locale("de");
export default Vue.extend({
    name: "RelativeTime",
    props: {
        dateTime: { type: [String, Date], required: true },
        hideIcon: { type: Boolean, default: false },
    },
    data: function () {
        return {
            timeIcon: mdiClockOutline,
        };
    },
    computed: {
        isXsScreenSize: function () {
            return this.$vuetify.breakpoint.name === "xs";
        },
        styles: function () {
            return {
                color: this.$vuetify.theme.themes.light.darkGrey,
                fontWeight: 400,
                fontFamily: fontFamily,
                fontSize: "".concat(this.isXsScreenSize ? 0.75 : 0.8, "rem"),
            };
        },
        fromNow: function () {
            return "".concat(moment(this.dateTime.toString()).fromNow());
        },
    },
});
