import { defineComponent, ref, watch } from "vue";
import AttachmentThumbnail from "./AttachmentThumbnail.vue";
import TextBody from "components/UI/TextBody.vue";
import { mdiDotsVertical, mdiClose, mdiCheck } from "@mdi/js";
import useTranslate from "features/common/translate/concerns/useTranslate";
import DialogConfirm from "components/UI/DialogConfirm.vue";
export default defineComponent({
    name: "AttachmentUploadTableItem",
    components: { TextBody: TextBody, AttachmentThumbnail: AttachmentThumbnail, DialogConfirm: DialogConfirm },
    props: {
        name: { type: String, default: "unnamed" },
        fileExtension: { type: String, default: "FILE" },
        thumbUrl: { type: String },
        uploadError: { type: String },
        uploadProgress: {
            type: Number,
            validator: function (value) {
                // The value must match one of these strings
                return value === undefined || value >= 0 || value <= 100;
            },
        },
        selected: { type: Boolean, default: false },
        editable: { type: Boolean, default: false },
        allowImageMode: { type: Boolean, default: false },
        imageModeBlockedMessage: { type: String, default: "" },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var t$ = useTranslate("components.attachment_upload_item").t$;
        var uploadName = ref(props.name);
        var isEditing = ref(false);
        var showDialog = ref(false);
        var emitEditAndStopEditing = function () {
            if (!uploadName.value) {
                closeEdit();
                return;
            }
            emit("uploadName", uploadName.value);
            isEditing.value = false;
        };
        var startEditing = function () {
            if (props.editable) {
                isEditing.value = true;
            }
        };
        var closeEdit = function () {
            isEditing.value = false;
            uploadName.value = props.name;
        };
        var nameRule = function (v) { return !!v || "Name wird benötigt."; };
        watch(isEditing, function (v) { return emit("editing", v); });
        return {
            mdiDotsVertical: mdiDotsVertical,
            mdiClose: mdiClose,
            mdiCheck: mdiCheck,
            t$: t$,
            nameRule: nameRule,
            uploadName: uploadName,
            isEditing: isEditing,
            showDialog: showDialog,
            closeEdit: closeEdit,
            startEditing: startEditing,
            emitEditAndStopEditing: emitEditAndStopEditing,
        };
    },
});
