import { __awaiter, __generator } from "tslib";
import { ref, computed, defineComponent, provide, inject, } from "vue";
import "suus-api";
import "interfaces/avatar.interface";
import "interfaces/menuItem.interface";
import PostForm from "features/posts/form/PostForm.vue";
import PopupMenu from "../UI/PopupMenu.vue";
import RelativeTime from "../UI/RelativeTime.vue";
import CommentList from "../Comment/CommentList.vue";
import DialogConfirm from "../UI/DialogConfirm.vue";
import TimeDelta from "../UI/TimeDelta.vue";
import { mdiPencilOutline, mdiDeleteOutline, mdiStar } from "@mdi/js";
import TextHTML from "components/UI/TextHTML.vue";
import "concerns/useContextName";
import Avatar from "components/UI/Avatar.vue";
import Subtitle from "components/UI/Subtitle.vue";
import useReaction from "concerns/useReaction";
import CommentButton from "components/Comment/CommentButton.vue";
import ReactionButton from "components/ReactionButton/index.vue";
import CommentForm from "components/Comment/CommentForm.vue";
import { MemberReaction } from "components/ReactionCount/reaction-count.types";
import PostAvatar from "components/Post/PostAvatar.vue";
import PostHeader from "components/Post/PostHeader.vue";
import AttachmentListItemGroup from "components/AttachmentListItemGroup.vue";
import "features/posts/form/post-form.types";
import PostGalleryPreview from "features/posts/gallery/PostGalleryPreview.vue";
import { PostLightboxService } from "features/posts/gallery/lightbox/post-lightbox.service";
import "features/gallery/lightbox/lightbox.service";
import CommentSection from "components/Comment/CommentSection.vue";
import ReactionCountFull from "components/ReactionCount/ReactionCountFull.vue";
import RenderedWysiwygContent from "components/Wysiwyg/RenderedWysiwygContent.vue";
import ReactionIcon from "components/ReactionIcon/ReactionIcon.vue";
export default defineComponent({
    name: "Post",
    components: {
        ReactionIcon: ReactionIcon,
        ReactionCountFull: ReactionCountFull,
        RenderedWysiwygContent: RenderedWysiwygContent,
        CommentSection: CommentSection,
        PostGalleryPreview: PostGalleryPreview,
        PostHeader: PostHeader,
        PostAvatar: PostAvatar,
        AttachmentListItemGroup: AttachmentListItemGroup,
        PostForm: PostForm,
        DialogConfirm: DialogConfirm,
        CommentList: CommentList,
        TimeDelta: TimeDelta,
        TextHTML: TextHTML,
        RelativeTime: RelativeTime,
        PopupMenu: PopupMenu,
        Avatar: Avatar,
        Subtitle: Subtitle,
        CommentButton: CommentButton,
        ReactionButton: ReactionButton,
        CommentForm: CommentForm,
    },
    props: {
        post: { type: Object, required: true },
        viewContext: { type: Object },
        updatePost: {
            type: Function,
        },
    },
    setup: function (props, context) {
        provide("viewContext", props.viewContext);
        var lightboxService = inject("lightboxService", undefined);
        if (lightboxService) {
            var postLightboxService = new PostLightboxService(lightboxService, props.post, props.viewContext);
            provide("PostLightboxService", postLightboxService);
        }
        var showCommentForm = ref(false);
        var initialReactionCounts = computed(function () {
            return {
                likesCount: props.post.likes_count,
                ideasCount: props.post.ideas_count,
                cheersCount: props.post.cheers_count,
            };
        });
        var initialUserReaction = computed(function () { var _a; return (_a = props.post.own_reaction) === null || _a === void 0 ? void 0 : _a.emoji; });
        var _a = useReaction({ id: props.post.id, type: "Post" }, initialReactionCounts, initialUserReaction), updateReaction = _a.updateReaction, createReaction = _a.createReaction, deleteReaction = _a.deleteReaction, likesCount = _a.likesCount, ideasCount = _a.ideasCount, cheersCount = _a.cheersCount, reactions = _a.reactions, userReaction = _a.userReaction;
        var memberReactions = computed(function () {
            return reactions.value.map(function (reaction) {
                return MemberReaction.fromReaction(reaction, props.viewContext);
            });
        });
        function handleDeleteReaction(val) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, deleteReaction(props.post.id.toString(), val)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function handleCreateReaction(val) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, createReaction(props.post.id.toString(), val)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function handleUpdateReaction(_a) {
            var prevReaction = _a.prevReaction, newReaction = _a.newReaction;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, updateReaction(props.post.id.toString(), newReaction, prevReaction)];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        var canCreateReaction = computed(function () { var _a; return (_a = props.post.links.reactions) === null || _a === void 0 ? void 0 : _a.create_url; });
        var canCreateComment = computed(function () { var _a; return !!((_a = props.post.links.comments) === null || _a === void 0 ? void 0 : _a.create_url); });
        var isEdit = ref(false);
        var isDialog = ref(false);
        var avatarData = computed(function () {
            var _a, _b, _c, _d;
            return {
                name: (_a = props.post) === null || _a === void 0 ? void 0 : _a.creator.internal_name_short,
                imageUrl: (_b = props.post) === null || _b === void 0 ? void 0 : _b.creator.avatar_url,
                profileUrl: (_c = props.post) === null || _c === void 0 ? void 0 : _c.creator.url,
                clients: (_d = props.post) === null || _d === void 0 ? void 0 : _d.creator.clients,
            };
        });
        function onPostUpdated(post) {
            context.emit("postUpdated", post);
            isEdit.value = false;
        }
        function deletePost() {
            context.emit("delete-post", props.post.id);
        }
        var menuItems = computed(function () {
            return [
                {
                    title: "Bearbeiten",
                    key: "edit",
                    clickHandler: function () { return (isEdit.value = true); },
                    icon: mdiPencilOutline,
                    permitted: !!props.post.links.update_url,
                },
                {
                    title: "Löschen",
                    key: "delete",
                    clickHandler: function () { return (isDialog.value = true); },
                    icon: mdiDeleteOutline,
                    permitted: !!props.post.links.delete_url,
                },
            ];
        });
        var permittedMenuItems = computed(function () {
            return menuItems.value.filter(function (item) { return item.permitted; });
        });
        var postAttachments = computed(function () { return props.post.attachments; });
        var highlightsCount = computed(function () {
            return props.post.highlights_count;
        });
        var highlightsCountTooltip = computed(function () {
            if (highlightsCount.value === 1) {
                return "Ein Foto wurde als Highlight markiert.";
            }
            return "".concat(highlightsCount.value, " Fotos wurden als Highlight markiert.");
        });
        return {
            isEdit: isEdit,
            isDialog: isDialog,
            onPostUpdated: onPostUpdated,
            deletePost: deletePost,
            avatarData: avatarData,
            permittedMenuItems: permittedMenuItems,
            showCommentForm: showCommentForm,
            likesCount: likesCount,
            ideasCount: ideasCount,
            cheersCount: cheersCount,
            userReaction: userReaction,
            canCreateReaction: canCreateReaction,
            canCreateComment: canCreateComment,
            handleDeleteReaction: handleDeleteReaction,
            handleCreateReaction: handleCreateReaction,
            handleUpdateReaction: handleUpdateReaction,
            memberReactions: memberReactions,
            postAttachments: postAttachments,
            mdiStar: mdiStar,
            highlightsCount: highlightsCount,
            highlightsCountTooltip: highlightsCountTooltip,
        };
    },
    computed: {
        isXsScreenSize: function () {
            return this.$vuetify.breakpoint.name === "xs";
        },
    },
});
