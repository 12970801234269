import { __assign } from "tslib";
import THEME from "../../constants/theme";
import Vue from "vue";
var button = THEME.typography.button;
export default Vue.extend({
    name: "Button",
    props: {
        btnLabel: { type: String, required: false },
        block: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        isLoading: { type: Boolean, default: false },
        color: { type: String, default: "primary" },
    },
    computed: {
        classArray: function () {
            return ["suus__btn ".concat(this.btnClass), "".concat(!this.block ? "px-8" : "")];
        },
        skeletonWidth: function () {
            return this.block ? "100%" : 190;
        },
        labelStyles: function () {
            return __assign({ color: "#fff" }, button);
        },
        btnClass: function () {
            var btnClass = "suus__btn--primary";
            if (this.color === "highlight") {
                btnClass = "suus__btn--highlight";
            }
            if (this.disabled) {
                btnClass = "suus__btn--disabled";
            }
            if (this.color === "dark-grey") {
                btnClass = "suus__btn--dark-grey";
            }
            return btnClass;
        },
    },
});
