import { __spreadArray } from "tslib";
import { defineComponent, ref, watch } from "vue";
import { useOmissionLoaderInternal, } from "features/omission-loader/use-omission-loader-internal";
import DefaultSkeletonLoader from "ui-primitives/loading-indicators/DefaultSkeletonLoader.vue";
import "features/common/shared/polymorphic";
import { useOmissionLoader } from "features/omission-loader/use-omission-loader";
import TextButton from "components/UI/TextButton.vue";
export default defineComponent({
    name: "OmissionLoader",
    components: {
        TextButton: TextButton,
        DefaultSkeletonLoader: DefaultSkeletonLoader,
    },
    props: {
        initialLeadingCount: {
            type: Number,
            required: true,
        },
        initialTrailingCount: {
            type: Number,
            required: true,
        },
        pageSize: {
            type: Number,
            required: true,
        },
        totalCount: {
            type: Number,
            required: true,
        },
        loadMore: {
            type: Function,
            required: true,
        },
    },
    setup: function (props, context) {
        var leadingItems = ref([]);
        var trailingItems = ref([]);
        var _a = useOmissionLoaderInternal(props.totalCount, props.pageSize, props.initialLeadingCount, props.initialTrailingCount, props.loadMore, function (items) {
            leadingItems.value = __spreadArray(__spreadArray([], leadingItems.value, true), items, true);
            context.emit("leadingLoaded", items);
            emitTotalLoaded();
        }, function (items) {
            trailingItems.value = __spreadArray(__spreadArray([], items, true), trailingItems.value, true);
            context.emit("trailingLoaded", items);
            emitTotalLoaded();
        }), isLastPage = _a.isLastPage, allLoaded = _a.allLoaded, loadOnlyLeading = _a.loadOnlyLeading, loadLeading = _a.loadLeading, loadTrailing = _a.loadTrailing, loadRemaining = _a.loadRemaining, trailingLoading = _a.trailingLoading, leadingLoading = _a.leadingLoading, remainingCount = _a.remainingCount, nextPageSize = _a.nextPageSize;
        var emitTotalLoaded = function () {
            context.emit("totalLoaded", leadingItems.value.length + trailingItems.value.length);
        };
        var _b = useOmissionLoader(), addedItem = _b.addedItem, removedItem = _b.removedItem, updatedItem = _b.updatedItem;
        watch(addedItem, function (_a) {
            var createdItem = _a.createdItem, toLeading = _a.toLeading;
            if (toLeading) {
                leadingItems.value = __spreadArray([createdItem], leadingItems.value, true);
            }
            else {
                trailingItems.value = __spreadArray(__spreadArray([], trailingItems.value, true), [createdItem], false);
            }
            emitTotalLoaded();
        });
        watch(removedItem, function (id) {
            leadingItems.value = leadingItems.value.filter(function (i) { return i.id !== id; });
            trailingItems.value = trailingItems.value.filter(function (i) { return i.id !== id; });
            emitTotalLoaded();
        });
        watch(updatedItem, function (_a) {
            var id = _a.id, updateFunction = _a.updateFunction;
            var isItemInLeading = leadingItems.value.some(function (i) { return i.id === id; });
            if (isItemInLeading) {
                leadingItems.value = leadingItems.value.map(function (i) {
                    if (i.id === id) {
                        return updateFunction(i);
                    }
                    return i;
                });
            }
            else {
                trailingItems.value = trailingItems.value.map(function (i) {
                    if (i.id === id) {
                        return updateFunction(i);
                    }
                    return i;
                });
            }
        });
        return {
            isLastPage: isLastPage,
            allLoaded: allLoaded,
            loadOnlyLeading: loadOnlyLeading,
            loadLeading: loadLeading,
            loadTrailing: loadTrailing,
            loadRemaining: loadRemaining,
            trailingLoading: trailingLoading,
            leadingLoading: leadingLoading,
            leadingItems: leadingItems,
            trailingItems: trailingItems,
            remainingCount: remainingCount,
            nextPageSize: nextPageSize,
        };
    },
});
