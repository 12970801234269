import Vue from "vue";
import { mdiCheckCircle } from "@mdi/js";
import ItemIconContent from "./ItemIconContent.vue";
import "vue";
import "components/TopicForm/general-fields/group-access";
export default Vue.extend({
    name: "ContentfulSelect",
    components: { ItemIconContent: ItemIconContent },
    props: {
        isMandatory: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        options: { type: Array, required: true },
        value: { type: String, default: null },
        label: { type: String, required: true },
        noDataText: { type: String, default: "" },
        itemValue: { type: String, default: "title" },
    },
    data: function () {
        return {
            checkIcon: mdiCheckCircle,
        };
    },
    computed: {
        selectedOption: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit("input", value);
            },
        },
        labelStyles: function () {
            return {
                color: this.$vuetify.theme.themes.light.primary,
                fontWeight: 400,
            };
        },
        listStyles: function () {
            return {
                borderBottom: "1px solid ".concat(this.$vuetify.theme.themes.light.lightGrey),
                fontFamily: "system-ui",
            };
        },
        mandatoryStyles: function () {
            return {
                color: "red",
                fontWeight: 400,
            };
        },
    },
    methods: {
        setIconColor: function (itemTitle) {
            if (itemTitle === this.selectedOption) {
                return "primary";
            }
            return "mediumGrey";
        },
    },
    watch: {
        selectedOption: function (val) {
            this.$emit("input", val);
        },
    },
});
