import "features/common/shared/polymorphic";
import { inject, provide } from "vue";
import { useEventRef } from "core/event-ref/use-event-ref";
var OmissionLoaderConcernKey = Symbol("OmissionLoaderService");
export var useOmissionLoader = function () {
    var existingOmmissionLoader = inject(OmissionLoaderConcernKey, null);
    if (existingOmmissionLoader)
        return existingOmmissionLoader;
    return provideOmissionLoader();
};
export var provideOmissionLoader = function () {
    var omissionLoader = createOmissionLoader();
    provide(OmissionLoaderConcernKey, omissionLoader);
    return omissionLoader;
};
var createOmissionLoader = function () {
    var addedItem = useEventRef(null);
    var removedItem = useEventRef(null);
    var updatedItem = useEventRef(null);
    return {
        addedItem: addedItem,
        removedItem: removedItem,
        updatedItem: updatedItem,
    };
};
