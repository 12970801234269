import { __assign } from "tslib";
import "core-js/stable";
import "regenerator-runtime/runtime";
// Note that we aren't binding the import to a variable. This is because polyfills simply run on their own,
// prior to the rest of the code base, allowing us to then assume certain native functionality exists.
// see https://webpack.js.org/guides/shimming/#loading-polyfills
import "isomorphic-fetch";
import "url-polyfill";
import "get-root-node-polyfill/implement";
import Vue from "vue";
import vuetify from "../initializers/vuetify";
import store from "../store";
import router from "../routes";
import "../initializers";
import { Portal } from "@linusborg/vue-simple-portal";
import PortalApp from "../components/Portal/PortalApp.vue";
import PortalAppPlain from "../components/Portal/PortalAppPlain.vue";
import AppContainer from "../components/AppContainer.vue";
import Memberlist from "../components/Memberlist.vue";
import Relations from "../components/Relations.vue";
import PeopleList from "../components/PeopleList.vue";
import ClientStats from "../components/ClientStats.vue";
import LevelStats from "../components/LevelStats.vue";
import AdminStats from "../components/AdminStats.vue";
import { i18n } from "./i18n";
import axios from "initializers/axios";
import ChangelogEntry from "features/changelog/ChangelogEntry.vue";
import CustomAttributeUpdater from "features/custom_attributes/CustomAttributeUpdater.vue";
import "features/gallery/dashboard/GalleryDashboardPortal.vue";
import BasicWysiwygEntry from "components/Wysiwyg/BasicWysiwygEntry.vue";
export { i18n };
// Used for loading indicators, don't remove (yet)
window.addEventListener("DOMContentLoaded", function () {
    axios.interceptors.request.use(function (config) {
        store.commit("loader/START_LOADING");
        return config;
    }, function (error) {
        store.commit("loader/FINISH_LOADING");
        return Promise.reject(error);
    });
    axios.interceptors.response.use(function (response) {
        store.commit("loader/FINISH_LOADING");
        return response;
    }, function (error) {
        store.commit("loader/FINISH_LOADING");
        return Promise.reject(error);
    });
});
// Is used for the Rails asset pipeline, don't remove
var images = require.context("../images", true);
//const imagePath = (name) => images(name, true)
/**
 * Register a Vue component to an element with the given ID.
 * The data attribute of the element will be passed as props to the component.
 * The data attribute should be a JSON string.
 *
 * Additional Vue options can be passed as the `use` parameter.
 *
 * A function can be passed as the `mapProps` parameter to transform the props before passing them to the component.
 *
 * @param elementId
 * @param component
 * @param use
 * @param mapProps
 */
var registerComponent = function (elementId, component, use, mapProps) {
    document.addEventListener("DOMContentLoaded", function () {
        var _a;
        var _b;
        var _mapProps = mapProps !== null && mapProps !== void 0 ? mapProps : (function (props) { return props; });
        var element = document.getElementById(elementId);
        if (!element)
            return;
        var props = _mapProps(JSON.parse((_b = element.getAttribute("data")) !== null && _b !== void 0 ? _b : null));
        new Vue(__assign({ el: "#".concat(elementId), render: function (h) { return h(component, { props: props }); }, components: (_a = {}, _a[component.name] = component, _a) }, use));
    });
};
var registerComponentByClass = function (className, component, use, mapProps) {
    document.addEventListener("DOMContentLoaded", function () {
        var _mapProps = mapProps !== null && mapProps !== void 0 ? mapProps : (function (props) { return props; });
        var elements = document.getElementsByClassName(className);
        if (!elements)
            return;
        Array.from(elements).forEach(function (element) {
            var _a;
            var _b;
            var props = _mapProps(JSON.parse((_b = element.getAttribute("data")) !== null && _b !== void 0 ? _b : null));
            new Vue(__assign({ el: element, render: function (h) { return h(component, { props: props }); }, components: (_a = {}, _a[component.name] = component, _a) }, use));
        });
    });
};
/**
 *  COMPONENT REGISTRATIONS
 */
registerComponentByClass("basic-wysiwyg", BasicWysiwygEntry, {
    vuetify: vuetify,
});
registerComponent("memberlist", Memberlist, {
    router: router,
    store: store,
    i18n: i18n,
});
registerComponent("addRelation", Relations);
registerComponent("drive-container", AppContainer, {
    vuetify: vuetify,
    router: router,
    store: store,
    i18n: i18n,
});
registerComponent("clientStats", ClientStats, {
    i18n: i18n,
});
registerComponent("levelStats", LevelStats, {
    i18n: i18n,
});
registerComponent("adminStats", AdminStats, {
    i18n: i18n,
});
registerComponent("peoplelist", PeopleList, {
    i18n: i18n,
});
registerComponent("app__vuetify", PortalApp, {
    router: router,
    store: store,
    vuetify: vuetify,
    i18n: i18n,
    Portal: Portal,
});
registerComponent("app__vuetify--plain", PortalAppPlain, {
    router: router,
    store: store,
    vuetify: vuetify,
    i18n: i18n,
    Portal: Portal,
});
registerComponent("giftbox", ChangelogEntry, {
    vuetify: vuetify,
    router: router,
}, function (props) { return ({ lastSeen: props.toString() }); });
registerComponent("custom_attribute_updater", CustomAttributeUpdater, {
    vuetify: vuetify,
    router: router,
}, function (props) { return ({ client_id: props }); });
