import { __awaiter, __generator } from "tslib";
import { ref, defineComponent } from "vue";
import Header from "../UI/Header.vue";
import TextBody from "../UI/TextBody.vue";
import "vue-i18n";
import "concerns/useContextName";
import useMentionSearch from "concerns/useMentionSearch";
import "modules/common/types/dtos";
import BasicWysiwyg from "components/Wysiwyg/BasicWysiwyg.vue";
import ForumWysiwygWithUploader from "components/Wysiwyg/ForumWysiwygWithUploader.vue";
import { topicsApi } from "store/api";
import "suus-api";
export default defineComponent({
    name: "TopicFormPanelDescription",
    components: { ForumWysiwygWithUploader: ForumWysiwygWithUploader, BasicWysiwyg: BasicWysiwyg, Header: Header, TextBody: TextBody },
    props: {
        inputText: { type: String },
        viewContext: { type: Object },
        inputTextValidation: { type: Array, default: function () { return []; } },
        addFile: { type: Function },
        updateValidationState: {
            type: Function,
        },
        topicId: { type: Number, required: false },
    },
    setup: function (props, context) {
        var _this = this;
        var wysiwygErrorMessage = ref(null);
        var validationState = ref(true);
        function updateValidationState(isValid) {
            validationState.value = isValid;
            props.updateValidationState(isValid);
        }
        function validate(val) {
            wysiwygErrorMessage.value = "";
            props.inputTextValidation.forEach(function (rule) {
                var message = rule(val);
                if (typeof message === "string") {
                    wysiwygErrorMessage.value += "".concat(message, " ");
                }
            });
            if (!validationState.value) {
                wysiwygErrorMessage.value +=
                    "Warte bis alle Anhänge hochgeladen sind oder entferne fehlerhafte Anhänge. ";
            }
        }
        function handleMouseLeave() {
            validate(props.inputText);
        }
        function handleInput(val) {
            context.emit("update-field", { key: "inputText", val: val });
            validate(val);
        }
        var getMentionableFunc = useMentionSearch(props.viewContext).mentionableFunction;
        var attachments = ref([]);
        var loadAttachments = function () { return __awaiter(_this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // if new topic is created, return empty array
                        if (!props.topicId) {
                            return [2 /*return*/, []];
                        }
                        return [4 /*yield*/, topicsApi.getTopicAttachments(props.topicId.toString())];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        // Delay loading until there is the first request for it & do it only once
        var getAttachments = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(attachments.value.length === 0)) return [3 /*break*/, 2];
                        _a = attachments;
                        return [4 /*yield*/, loadAttachments()];
                    case 1:
                        _a.value = _b.sent();
                        console.log("Loading attachments the first time", attachments.value);
                        _b.label = 2;
                    case 2:
                        console.log("Returning already loaded attachments", attachments.value);
                        return [2 /*return*/, attachments.value];
                }
            });
        }); };
        var loadAttachment = function (frontendId) { return __awaiter(_this, void 0, Promise, function () {
            var attachments, attachment;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getAttachments()];
                    case 1:
                        attachments = _a.sent();
                        attachment = attachments.find(function (a) { return a.frontend_id === frontendId; });
                        if (attachment) {
                            return [2 /*return*/, attachment];
                        }
                        else {
                            console.error("Attachment not found!", frontendId, attachments);
                            throw "Attachment not in list of topic attachments!";
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        return {
            handleInput: handleInput,
            getMentionableFunc: getMentionableFunc,
            handleMouseLeave: handleMouseLeave,
            wysiwygErrorMessage: wysiwygErrorMessage,
            updateValidationState: updateValidationState,
            loadAttachment: loadAttachment,
        };
    },
    computed: {
        topicTextInfo: function () {
            return this.$t("components.topic_form.wysiwyg_info");
        },
    },
});
