<template lang="pug">
div
  sui-message(success
    v-if="showSuccess"
      icon="suus-check-bold"
      header="Ordner erstellt"
      content="Du befindest dich nun in deinem Ordner."
      dismissable
      @dismiss="showSuccess = false"
  )
  .ui.segment.form.new_folder(v-if="open")
    .ui.grid.stackable
      .three.wide.column.mobile.hidden.text-center
        h3(is="sui-header" icon)
          sui-icon(:name="icon" size="big")
          sui-header-content Ordner

      .thirteen.wide.column.hidden
        sui-form-field(required)
          LabelWithHint(tooltip-key="folders.edit.name.tooltip") Name
          sui-input(type="text" placeholder="Dateiname" v-model="name" name="name")
          sui-label.hovering(v-if="!$v.name.required" pointing color="red") Name ist erforderlich

        sui-form-fields(fields="two")
          sui-form-field
            LabelWithHint(tooltip-key="folders.edit.series.tooltip") Serie
            sui-checkbox(label="Serie" toggle v-model="series")

          sui-form-field(inline)
            label(class="select") Ordnersortierung
            SuiDropdown(
              :options="orderOptions"
              v-model="ordering"
              multiple
              fluid
              selection
            )

        sui-form-field
          LabelWithHint(tooltip-key="folders.edit.description.tooltip") Beschreibung
          textarea(rows=3 placeholder="Beschreibung" v-model="description" name="description")

        h2 Berechtigungen

        InodeAclInput(ref="acl" :inode="folder")

    .ui.message.visible.error(v-if="error") Ordner konnte nicht gespeichert werden ({{ error.response.status }}).
    .error(v-if="$v.$anyError") Das Formular ist nicht korrekt ausgefüllt.
    .ui.button.primary(@click="save" :class="{disabled: $v.$anyError}") Speichern
    .ui.button(@click="$router.go(-1)") Abbrechen
</template>

<script>
import InodeAclInput from "./InodeAclInput.vue"
import RemoteDropdown from "../RemoteDropdown.vue"
import ListItem from "../ListItem.vue"
import { required, minLength, between } from "vuelidate/lib/validators"
import { mapGetters, mapState, mapMutations, mapActions } from "vuex"
import LabelWithHint from "../LabelWithHint.vue"

export default {
  name: "FolderForm",
  props: {
    folder: { type: [Object], required: true },
    state: [String],
  },
  data() {
    return {
      name: this.folder.name,
      description: this.folder.description,
      series: this.folder.series,
      error: false,
      expanded: false,
      open: true,
      showSuccess: false,
      ordering: this.folder.ordering,
    }
  },
  validations: {
    name: {
      required,
    },
  },
  computed: {
    orderOptions() {
      return [
        { value: "<name", text: "Name (A-Z)" },
        { value: ">name", text: "Name (Z-A)" },
        { value: "<created_at", text: "Hochgeladen (Alt -> Neu)" },
        { value: ">created_at", text: "Hochgeladen (Neu -> Alt)" },
        { value: "<physically_created_at", text: "Datierung (Alt -> Neu)" },
        { value: ">physically_created_at", text: "Datierung (Neu -> Alt)" },
      ]
    },
    type() {
      return this.series ? "Container" : "Folder"
    },
    formData() {
      const acl_data = this.$refs.acl ? this.$refs.acl.value : {}

      return {
        inode: {
          id: this.folder.id,
          name: this.name,
          ordering: this.ordering,
          type: this.type,
          description: this.description,
          ...acl_data,
        },
      }
    },
    icon() {
      const container = "suus-single-copies"
      const folder = "suus-folder-13"
      return this.series ? container : folder
    },
    isPersisted() {
      return !!this.folder.id
    },
  },
  methods: {
    async save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          if (this.folder.id) {
            await this.updateInode({
              inode: this.folder,
              formData: this.formData,
            })
            this.$router.push({
              name: "drive/folder",
              params: { inode_id: this.folder.id },
            })
          } else {
            const { id } = await this.createFolder(this.formData)
            this.$router.push({
              name: "drive/folder",
              params: { inode_id: id },
            })
          }
          this.$store.commit("drive/setSuccess", "Erfolgreich gespeichert")
        } catch (e) {
          console.log(e)
          this.setError(e)
        }
      }
    },
    ...mapMutations("drive", ["setError"]),
    ...mapActions("drive", ["createFolder", "updateInode"]),
  },
  components: {
    LabelWithHint,
    InodeAclInput,
    RemoteDropdown,
    ListItem,
  },
}
</script>

<style scoped lang="sass">
.new_folder
  .item .header .field
    clear: both
    margin: 2px 2px 0 !important

.upload-fields
  display: flex
  .field
    padding-right: 1rem
</style>
