<template lang="pug">
LoaderWrapper(:requires="[currentInode]")
  Header
    template(#buttons)
      UploadButton(
        :signingUrl="currentInode.signing_url"
        :uploaderOpen="uploaderOpen"
        :showUploader="showUploader"
      )

      router-link.ui.icon.button.mobile.hidden(
        v-tooltip="$t('drive.createFolderButton.tooltip')"
        v-if="currentInode.create_subfolder_url"
        :to="{name: 'drive/folder/new', params: { inode_id: currentInode.id} }"
      )
        i.ui.icon.suus-folder-add

      router-link.ui.icon.button.mobile.hidden(
        v-tooltip="$t('drive.editFolderButton.tooltip')"
        v-if="currentInode.update_url"
        :to="{name: 'drive/folder/edit', params: { inode_id: currentInode.id} }"
      )
        i.ui.icon.suus-settings-tool-67

      Pin.mobile.hidden(:inode="currentInode" v-if="currentInode.pin_url")

      a.ui.icon.button(:href="currentInode.download_url" target="new" v-if="currentInode.download_url" v-tooltip="$t('drive.folderDownloadButton.tooltip')")
        i.ui.icon.suus-hit-down

      a.ui.icon.button(:href="currentInode.backup_url" target="new" v-if="currentInode.backup_url" v-tooltip="$t('drive.folderBackupButton.tooltip')")
        i.ui.icon.suus-cloud-download-93

      sui-dropdown.icon.dots.mobile.and.tablet.only(icon='suus-menu-dots' button direction="downward" v-if="currentInode.id && currentInode.destroy_url")
        sui-dropdown-menu
          router-link.item(
            v-tooltip="$t('drive.createFolderButton.tooltip')"
            v-if="currentInode.create_subfolder_url"
            :to="{name: 'drive/folder/new', params: { inode_id: currentInode.id} }"
          )
            i.ui.icon.suus-folder-add
            | Ordner erstellen

          router-link.item(
            v-tooltip="$t('drive.editFolderButton.tooltip')"
            v-if="currentInode.update_url"
            :to="{name: 'drive/folder/edit', params: { inode_id: currentInode.id} }"
          )
            i.ui.icon.suus-settings-tool-67
            | Bearbeiten

          Pin.item(:inode="currentInode" v-if="currentInode.pin_url") Anpinnen



  drive-uploader(
    :parent="currentInode"
    @created="$refs.lazyItems.load()"
  )
  FolderForm(
    :parent="currentInode"
    v-if="createFolder"
    @created="folderCreated"
  )
  
  
  sui-checkbox(toggle v-model="showGallery" label="Galerieansicht" v-if="isGallery")

  .ui.divided.items.vuejs(v-if="path.length > 1 && currentInode")
    ListItem
      template(#image)
        sui-icon(name="suus-folder-upload")
      template(#title)
        router-link.title(:to="{name: 'drive/folder', params: { inode_id: currentInode.parent_id} }")
          | {{$t('drive.parentFolder', {parentName: parentFolder.name, interpolation: {escapeValue: false } })}}
  template(v-if="showGalleryView")
    .ui.divided.items.vuejs(v-if="gallery_folders.length > 0")
      component(v-for="folder in gallery_folders" :is="folder.type" :inode="folder" v-bind:key="folder.id")
    VuePreview(:slides="gallery" @close="handleClose")
  template(v-else)
    LazyList(ref="lazyItems"
      v-if="currentInode"
      :endpoint="endpoint"
      :placeholders="{items: currentInode.inodes_count, slots: ['image', 'title']}"
      @offset="updateProgress"
      :initialOffset="offset"
      :getResultsFun="() => currentContents"
      :setResultsFun="updateInodes"
      :order="ordering"
    )
      template(#item="{item: inode}")
        component(:is="inode.type" :inode="inode")
      template(#empty)
        p.no-elements
          | Dieser Ordner ist leer.
</template>

<script>
import LoaderWrapper from "../LoaderWrapper.vue"
import LazyList from "../../LazyList.vue"
import ListItem from "../../ListItem.vue"
import DriveUploader from "../DriveUploader.vue"
import FolderForm from "../FolderForm.vue"

import DriveFile from "../ListView/DriveFile.vue"
import Folder from "../ListView/Folder.vue"
import Gallery from "../ListView/Gallery.vue"
import Photo from "../ListView/Photo.vue"
import ContainerFile from "../ListView/ContainerFile.vue"
import Container from "../ListView/Container.vue"
import Pin from "../Pin.vue"
import { createNamespacedHelpers } from "vuex"
const { mapGetters, mapState, mapMutations, mapActions } =
  createNamespacedHelpers("drive")
import Header from "../Header.vue"
import moment from "moment"
import LabelWithHint from "../../LabelWithHint.vue"
import UploadButton from "components/Drive/Views/file-browser-components/UploadButton.vue"
import { computed, ref, watch } from "vue"
import { WritableComputedRef } from "vue"

const VuePreview = () => import("components/Drive/Views/VuePreview.vue")

export default {
  name: "FileBrowser",
  data() {
    return {
      createFolder: false,
    }
  },
  watch: {
    $route() {
      this.createFolder = false
    },
    async endpoint() {
      if (this.isGallery) {
        const { data } = await this.$http.get(this.endpoint)
        this.updateInodes(data.results)
      }
    },
  },
  async mounted() {
    if (this.isGallery) {
      const { data } = await this.$http.get(this.endpoint)
      this.updateInodes(data.results)
    }
  },
  methods: {
    folderCreated(folder) {
      this.$router.push({
        name: "drive/folder",
        params: { inode_id: folder.id },
      })
    },
    updateProgress(offset) {
      this.setLoadingProgress({ inodeId: this.currentInode.id, offset })
    },
    handleClose() {
      console.log("close event")
    },
    ...mapMutations([
      "updateInodes",
      "showUploader",
      "setLoadingProgress",
      "setError",
    ]),
    ...mapActions(["loadInode"]),
  },
  computed: {
    isGallery() {
      return this.currentInode && this.currentInode.is_gallery
    },
    showGalleryView() {
      return this.showGallery && this.isGallery
    },
    endpoint() {
      const url = new URL(window.location.origin + "/api/v1/drive/folders")
      if (this.currentInode && this.currentInode.id) {
        url.searchParams.append("parent_id", this.currentInode.id)
        url.searchParams.append("order", this.currentInode.ordering.join("|"))
      }
      return url.toString()
    },
    gallery() {
      if (this.isGallery) {
        return this.currentContents
          .filter((el) => el.type === "Photo")
          .map((el) => {
            const { width: w, height: h } = el.original
            return {
              src: el.preview.url,
              msrc: el.thumb.url,
              alt: el.description,
              title: el.name,
              w,
              h,
            }
          })
      } else {
        return []
      }
    },
    gallery_folders() {
      console.log("gallery_folders", this.currentContents)

      if (this.isGallery) {
        return this.currentContents.filter((el) => el.type !== "Photo")
      } else {
        return []
      }
    },
    offset() {
      return this.loadingProgress[this.currentInode.id]
    },
    ordering() {
      // implicit ordering by type
      const mapping = {
        Container: 1,
        Gallery: 2,
        Folder: 3,
        Photo: 4,
        DriveFile: 4,
      }
      let ordering = [
        {
          type: {
            order: "asc",
            transform(type) {
              return mapping[type]
            },
          },
        },
      ]

      const datetimeFields = [
        "created_at",
        "physically_created_at",
        "updated_at",
      ]
      for (let fieldstr of this.currentInode.ordering) {
        const field = fieldstr.slice(1)
        const dir = fieldstr[0] === ">" ? "desc" : "asc"
        let opts = { order: dir }
        if (datetimeFields.includes(field)) {
          opts = {
            ...opts,
            transform(x) {
              return moment(x).valueOf()
            },
          }
        }
        ordering = ordering.concat({ [field]: opts })
      }
      return ordering
    },
    ...mapState(["uploaderOpen", "loadingProgress"]),
    ...mapGetters(["path", "currentInode", "parentFolder", "currentContents"]),
  },
  components: {
    UploadButton,
    LabelWithHint,
    Header,
    LoaderWrapper,
    DriveUploader,
    LazyList,
    ListItem,
    FolderForm,
    Pin,
    DriveFile,
    Folder,
    Photo,
    ContainerFile,
    Container,
    Gallery,
    VuePreview,
  },
  setup() {
    const showGallery = ref(true)

    return {
      showGallery,
    }
  },
}
</script>

<style lang="sass">
.my-gallery
  display: grid
  grid-template-columns: repeat(7, 1fr)
  @media screen and (max-width: 1000px)
      grid-template-columns: repeat(5, 1fr)
  @media screen and (max-width: 700px)
    grid-template-columns: repeat(3, 1fr)
  grid-gap: 1em

  figure
    width: 100%
    height: 100%
    object-fit: cover
    display: inline-block
    margin: 0

    & > a
      overflow: hidden
      display: block
      & > img
        width: 100%
        height: auto
</style>
