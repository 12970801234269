import { defineComponent } from "vue";
import TextBody from "components/UI/TextBody.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import Hyperlink from "components/UI/Hyperlink.vue";
import Button from "components/UI/Button.vue";
export default defineComponent({
    name: "EventPassedContent",
    components: { Button: Button, Hyperlink: Hyperlink, TextBody: TextBody },
    setup: function () {
        var t$ = useTranslate("components.event_card.body_text.EVENT_PASSED").t$;
        var scrollToPostform = function () {
            var postform = document.getElementById("post-form");
            if (postform) {
                postform.scrollIntoView({ behavior: "smooth" });
            }
        };
        return { t$: t$, scrollToPostform: scrollToPostform };
    },
});
