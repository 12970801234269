import { __spreadArray } from "tslib";
import { computed, defineComponent, inject, ref } from "vue";
import "features/gallery/gallery.types";
import "features/media/media.types";
import MediaUploader from "features/media/MediaUploader.vue";
import GalleryThumbnail from "features/gallery/thumbnail/GalleryThumbnail.vue";
import GalleryUploaderGrid from "features/gallery/uploader/GalleryUploaderGrid.vue";
import { mdiRefresh, mdiTrashCanOutline } from "@mdi/js";
import "features/common/confirmation-dialog/confirmation.service";
import useTranslate from "features/common/translate/concerns/useTranslate";
import "suus-api";
import { getErrorMessageHtml, isFileSupported, } from "app/modules/common/supported-image-formats";
export default defineComponent({
    name: "GalleryUploader",
    components: { GalleryUploaderGrid: GalleryUploaderGrid, GalleryThumbnail: GalleryThumbnail, MediaUploader: MediaUploader },
    props: {
        existingGalleryMedia: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    setup: function (props, context) {
        var confirmationService = inject("ConfirmationService");
        var galleryUploads = ref([]);
        var mediaUploader = ref(null);
        var emitUploads = function () {
            context.emit("uploads", galleryUploads.value);
        };
        var emitValid = function (valid) {
            context.emit("valid", valid);
        };
        var emitDeleteExisting = function (medium) {
            if (typeof medium === "string") {
                context.emit("deleteExisting", medium);
            }
            else {
                context.emit("deleteExisting", medium.id);
            }
        };
        var updateUploads = function (uploads) {
            galleryUploads.value = uploads;
            emitUploads();
        };
        var t$ = useTranslate("components.gallery_uploader.delete_confirmation").t$;
        var buildActionBar = function (deleteUploadFunction, entity) {
            return [
                {
                    action: function () {
                        confirmationService
                            .openConfirmation(t$(".title"), t$(".text"), t$(".delete"))
                            .then(function (confirmed) {
                            console.log("confirmed", confirmed);
                            if (confirmed) {
                                deleteUploadFunction(entity);
                            }
                        });
                    },
                    icon: mdiTrashCanOutline,
                },
            ];
        };
        var retryUpload = function (token) {
            if (token) {
                console.log("retryUpload", mediaUploader.value);
                mediaUploader.value.retry(token);
            }
        };
        var wrongFormatFiles = ref([]);
        var validateFileBeforeUpload = function (file) {
            var isSupported = isFileSupported(file);
            if (!isSupported) {
                wrongFormatFiles.value = __spreadArray(__spreadArray([], wrongFormatFiles.value, true), [file], false);
                if (!showSnackbar.value) {
                    openSnackbar();
                }
                return false;
            }
            return true;
        };
        var errorHtml = computed(function () {
            return getErrorMessageHtml(wrongFormatFiles.value);
        });
        var showSnackbar = ref(false);
        var openSnackbar = function () {
            showSnackbar.value = true;
        };
        var closeSnackbar = function (shouldClose) {
            if (shouldClose === void 0) { shouldClose = true; }
            if (!shouldClose)
                return;
            showSnackbar.value = false;
            // Timeout removes flickering between different error messages
            setTimeout(function () {
                wrongFormatFiles.value = [];
            }, 300);
        };
        return {
            emitDeleteExisting: emitDeleteExisting,
            emitValid: emitValid,
            galleryUploads: galleryUploads,
            updateUploads: updateUploads,
            buildActionBar: buildActionBar,
            mediaUploader: mediaUploader,
            retryUpload: retryUpload,
            mdiRefresh: mdiRefresh,
            validateFileBeforeUpload: validateFileBeforeUpload,
            errorHtml: errorHtml,
            showSnackbar: showSnackbar,
            closeSnackbar: closeSnackbar,
        };
    },
});
