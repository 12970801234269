import { __awaiter, __generator } from "tslib";
import "suus-api";
import { groupApi, groupMemberApi } from "store/api";
export var fetchGroups = function (client_id, level_id) { return __awaiter(void 0, void 0, Promise, function () {
    var response, groups;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                console.log("fetchGroups", client_id, level_id);
                return [4 /*yield*/, groupApi.getGroups(undefined, level_id !== null && level_id !== void 0 ? level_id : undefined, client_id !== null && client_id !== void 0 ? client_id : undefined)];
            case 1:
                response = _b.sent();
                groups = (_a = response.data) === null || _a === void 0 ? void 0 : _a.results;
                return [2 /*return*/, groups || []];
        }
    });
}); };
export var fetchGroupMembers = function (searchParam) { return __awaiter(void 0, void 0, Promise, function () {
    var response, members;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, groupMemberApi.getGroupMembers(searchParam)];
            case 1:
                response = _b.sent();
                members = (_a = response.data) === null || _a === void 0 ? void 0 : _a.results;
                return [2 /*return*/, members || []];
        }
    });
}); };
