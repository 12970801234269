<template lang="pug">
LoaderWrapper(:requires="[currentInode]")
  Header
  FolderForm(:folder="newFolder")
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapGetters, mapState, mapMutations, mapActions } =
  createNamespacedHelpers("drive")
import LoaderWrapper from "../LoaderWrapper.vue"
import FolderForm from "../FolderForm.vue"
import Header from "../Header.vue"
export default {
  name: "CreateFolder",
  data() {
    return {}
  },
  components: { Header, FolderForm, LoaderWrapper },
  async mounted() {
    await this.setCurrentInode(this.$route.params.inode_id)
  },
  computed: {
    parent() {
      return this.currentInode
    },
    newFolder() {
      if (this.parent) {
        return {
          name: "",
          description: "",
          series: false,
          owner_type: this.parent.owner_type,
          owner_id: this.parent.owner_id,
          owner: this.parent.owner,
          restrict_to_id: this.parent.restrict_to_id,
          level_id: this.parent.level_id,
          parent_id: this.parent?.id,
          parent: this.parent,
          ordering: this.parent.ordering || ["<name"],
        }
      }
    },
    ...mapGetters(["currentInode"]),
  },
  methods: {
    ...mapActions(["setCurrentInode"]),
  },
}
</script>
