import { computed, ref, watch } from "vue";
import { mdiAccountGroupOutline, mdiAccountLockOutline, mdiEyeOffOutline, mdiEyeOutline, } from "@mdi/js";
import { GroupAccess, isInAccessOptions, } from "components/TopicForm/general-fields/group-access";
import useTranslate from "features/common/translate/concerns/useTranslate";
import "suus-api";
import "components/UI/ContentfulSelect/index.vue";
export function useAccessOptions(group, memberships, selectedAccess, updateSelectedAccess) {
    var t$ = useTranslate("components.topic_form").t$;
    var OPEN = t$(".open");
    var INTERNAL = t$(".internal");
    var CONFIDENTIAL = t$(".confidential");
    var VISIBLE_FROM_OUTSIDE_GROUP = t$(".visible_from_outside_group");
    var NOT_VISIBLE_FROM_OUTSIDE_GROUP = t$(".not_visible_from_outside_group");
    var PARTICIPATE_FROM_OUTSIDE_GROUP = t$(".participate_from_outside_group");
    var PARTICIPATE_WITHIN_GROUP_ONLY = t$(".participate_within_group_only");
    var open = {
        title: OPEN,
        type: GroupAccess.OPEN,
        contentItems: [
            {
                icon: mdiEyeOutline,
                text: VISIBLE_FROM_OUTSIDE_GROUP,
            },
            {
                icon: mdiAccountGroupOutline,
                text: PARTICIPATE_FROM_OUTSIDE_GROUP,
            },
        ],
    };
    var internal = {
        title: INTERNAL,
        type: GroupAccess.INTERNAL,
        contentItems: [
            {
                icon: mdiEyeOutline,
                text: VISIBLE_FROM_OUTSIDE_GROUP,
            },
            {
                icon: mdiAccountLockOutline,
                text: PARTICIPATE_WITHIN_GROUP_ONLY,
            },
        ],
    };
    var confidential = {
        title: CONFIDENTIAL,
        type: GroupAccess.CONFIDENTIAL,
        contentItems: [
            {
                icon: mdiEyeOffOutline,
                text: NOT_VISIBLE_FROM_OUTSIDE_GROUP,
            },
            {
                icon: mdiAccountLockOutline,
                text: PARTICIPATE_WITHIN_GROUP_ONLY,
            },
        ],
    };
    var accessOptions = ref([open]);
    var isOwnGroup = computed(function () {
        var _a;
        return ((_a = group === null || group === void 0 ? void 0 : group.value) === null || _a === void 0 ? void 0 : _a.id) && (memberships === null || memberships === void 0 ? void 0 : memberships.value)
            ? memberships.value.includes(group.value.id)
            : false;
    });
    var isClosedGroup = computed(function () {
        var _a, _b;
        return ((_a = group === null || group === void 0 ? void 0 : group.value) === null || _a === void 0 ? void 0 : _a.closed) ? (_b = group === null || group === void 0 ? void 0 : group.value) === null || _b === void 0 ? void 0 : _b.closed : false;
    });
    function setFirstAccessOptionAsDefault(accessOptions) {
        if ((accessOptions === null || accessOptions === void 0 ? void 0 : accessOptions.length) >= 1) {
            if (isOwnGroup.value && !isClosedGroup.value) {
                updateSelectedAccess(internal.type);
                return;
            }
            updateSelectedAccess(accessOptions[0].type);
        }
    }
    watch(group, function (newVal, oldVal) {
        if (isClosedGroup.value) {
            accessOptions.value = [confidential];
        }
        else if (isOwnGroup.value && !isClosedGroup.value) {
            accessOptions.value = [open, internal, confidential];
        }
        else {
            accessOptions.value = [open];
        }
        var needsUpdate = !selectedAccess.value ||
            !isInAccessOptions(accessOptions.value, selectedAccess.value);
        if (needsUpdate)
            setFirstAccessOptionAsDefault(accessOptions.value);
    }, { immediate: true });
    return accessOptions;
}
