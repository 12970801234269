import { __assign } from "tslib";
import { ref, computed, defineComponent, watch } from "vue";
import THEME from "../../constants/theme";
import { HTMlfromString, getTextContextLength } from "helpers/parseHTML";
import useMentionSearch from "concerns/useMentionSearch";
import "concerns/useContextName";
import DialogConfirm from "components/UI/DialogConfirm.vue";
import BasicWysiwyg from "components/Wysiwyg/BasicWysiwyg.vue";
export default defineComponent({
    name: "CommentForm",
    components: { BasicWysiwyg: BasicWysiwyg, DialogConfirm: DialogConfirm },
    props: {
        viewContext: { type: Object },
        value: {
            type: String,
            default: "",
        },
        inline: { type: Boolean, default: false },
    },
    setup: function (props, context) {
        var formValue = ref(props.value);
        watch(props, function (props) { return (formValue.value = props.value); });
        var errorText = ref("");
        var disabled = computed(function () { return !!errorText.value.length; });
        var commentRules = [
            function (v) {
                var isEmpty = (!v && !v.length) || v.toString() === "<p></p>";
                return isEmpty ? "Bitte gib ein Kommentar ein." : true;
            },
            function (v) {
                var node = HTMlfromString(v);
                var textLength = getTextContextLength(node);
                var isTooLong = !!v && !!(textLength > 300);
                return isTooLong
                    ? "Dein Kommentar überschreitet das Maximum von 300 Zeichen."
                    : true;
            },
        ];
        function validateComment(comment) {
            errorText.value = "";
            commentRules.forEach(function (rule) {
                var message = rule(comment);
                if (typeof message === "string") {
                    errorText.value += "".concat(message, " ");
                }
            });
        }
        function handleSubmit(value) {
            context.emit("save", formValue.value);
            formValue.value = "";
        }
        var buttonLabelStyles = computed(function () {
            return __assign({ color: disabled.value
                    ? THEME.colors.light.mediumGrey
                    : THEME.colors.light.primary }, THEME.typography.button);
        });
        var cancelButtonLabelStyles = computed(function () {
            return __assign({ color: THEME.colors.light.darkGrey }, THEME.typography.button);
        });
        var buttonContainerStyles = {
            backgroundColor: "#fff",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            border: "1px solid ".concat(THEME.colors.light.mediumGrey),
        };
        var wysiwygContainerStyles = {
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            border: "1px solid ".concat(THEME.colors.light.mediumGrey),
            borderBottom: "none",
        };
        var errorMessageStyles = __assign({}, THEME.typography.inputMessage);
        var placeholder = "Hier klicken und Dein Kommentar eingeben (Maximum 300 Zeichen).";
        var getMentionableFunc = useMentionSearch(props.viewContext).mentionableFunction;
        var cancelAndReset = function () {
            context.emit("cancel");
            formValue.value = props.value;
        };
        return {
            formValue: formValue,
            getMentionableFunc: getMentionableFunc,
            buttonLabelStyles: buttonLabelStyles,
            buttonContainerStyles: buttonContainerStyles,
            wysiwygContainerStyles: wysiwygContainerStyles,
            cancelButtonLabelStyles: cancelButtonLabelStyles,
            errorMessageStyles: errorMessageStyles,
            placeholder: placeholder,
            validateComment: validateComment,
            handleSubmit: handleSubmit,
            cancelAndReset: cancelAndReset,
            showConfirmCancelDialog: ref(false),
            errorText: errorText,
            disabled: disabled,
        };
    },
});
