<template>
  <div>
    <EventAttendAlert
      :attendStatus="attendStatus"
      :guestCount="guestCount"
      :guestsAllowed="guestsAllowed"
      @toggleAttending="toggleAttending($event)"
    ></EventAttendAlert>
    <v-tabs v-model="tab" background-color="transparent" centered>
      <v-tab key="attend">{{ t$(".tabs.attendTab") }}</v-tab>
      <v-tab key="decline">{{ t$(".tabs.declineTab") }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="background-color: transparent">
      <v-tab-item key="attend">
        <div class="d-flex justify-center">
          <AddEditAttendeeDialog
            v-if="isCurrentMemberOrganizerOrAllowed"
            :response="RsvpReply.Attend"
            :fetchAttendees="getSearchFunction"
            :guestsAllowed="guestsAllowed"
            :viewContext="viewContext"
            :selected-items="[
              ...coetanen,
              ...otherAttendees,
              ...declinedAttendees,
            ]"
            @save="handleCreateAttendee($event)"
          >
            <template v-slot="{ click, attrs }">
              <Button
                :btn-label="t$('components.add_attendee_button.label')"
                @click="click"
                class="mb-4"
              ></Button>
            </template>
          </AddEditAttendeeDialog>
        </div>
        <EventAttendeeListSection
          class="mb-8"
          :attendees="coetanen"
          :attendeesLoading="attendeesLoading"
          :title="AttendingCoetanenSubtitle"
          :guestsAllowed="guestsAllowed"
          :is-load-more-available="false"
          :is-editable="isCurrentMemberOrganizerOrAllowed"
          @editAttendee="handleAttendeeEdit"
        ></EventAttendeeListSection>
        <EventAttendeeListSection
          class="mb-8"
          :attendees="otherAttendees"
          :attendees-loading="attendeesLoading"
          :title="AttendingMembersSubtitle"
          :guestsAllowed="guestsAllowed"
          :is-editable="isCurrentMemberOrganizerOrAllowed"
          :is-load-more-available="isMoreRSVPAvailable"
          @loadMore="loadMoreRSVP"
          @editAttendee="handleAttendeeEdit"
        ></EventAttendeeListSection>
      </v-tab-item>
      <v-tab-item key="decline">
        <div class="d-flex justify-center">
          <AddEditAttendeeDialog
            v-if="isCurrentMemberOrganizerOrAllowed"
            :response="RsvpReply.Decline"
            :fetchAttendees="getSearchFunction"
            :guestsAllowed="guestsAllowed"
            :selected-items="[
              ...coetanen,
              ...otherAttendees,
              ...declinedAttendees,
            ]"
            @save="handleCreateAttendee($event)"
          >
            <template v-slot="{ click, attrs }">
              <Button
                :btn-label="t$('components.add_attendee_button.label')"
                @click="click"
                class="mb-4"
              ></Button>
            </template>
          </AddEditAttendeeDialog>
        </div>
        <EventAttendeeListSection
          class="mb-8 mt-2"
          :attendees="declinedAttendees"
          :attendees-loading="attendeesLoading"
          :title="DeclinedMemberSubtitle"
          :guestsAllowed="guestsAllowed"
          :is-editable="isCurrentMemberOrganizerOrAllowed"
          :is-load-more-available="isMoreDeclinedRSVPAvailable"
          @loadMore="loadMoreDeclinedRSVP"
          @editAttendee="handleAttendeeEdit"
        ></EventAttendeeListSection>
      </v-tab-item>
    </v-tabs-items>
    <TextBody
      v-if="!otherAttendees"
      class="text-center mb-4"
      :text="noAttendeesText"
    ></TextBody>
  </div>
</template>

<script>
import { EVENT_STATUS } from "constants/constants"
import { createNamespacedHelpers } from "vuex"

const { mapState, mapMutations, mapActions, mapGetters } =
  createNamespacedHelpers("event")
const { mapGetters: mapMemberGetters } = createNamespacedHelpers("member")

import { mdiAccountMultipleOutline } from "@mdi/js"
import StatusIcon from "../UI/StatusIcon.vue"
import Header from "../UI/Header.vue"
import TextButton from "../UI/TextButton.vue"
import TextBody from "../UI/TextBody.vue"
import AvatarCard from "../UI/AvatarCard.vue"
import Subtitle from "../UI/Subtitle.vue"
import GuestModal from "./GuestModal.vue"
import { defineComponent } from "vue"
import EventAttendeeListSection from "features/events/attendees/event-attendee-list/EventAttendeeListSection"
import EventAttendAlert from "features/events/attendees/event-attend-alert/EventAttendAlert.vue"
import useTranslate from "features/common/translate/concerns/useTranslate"
import AddEditAttendeeDialog from "features/events/attendees/add-edit-attendee/AddEditAttendeeDialog.vue"
import Button from "components/UI/Button.vue"
import { membersApi, rsvpApi } from "store/api"
import useLoadMoreRSVPs from "features/common/pagination-and-loader/concerns/useLoadMoreRSVPs"
import store from "../../store"
import { RsvpReply } from "suus-api"

export default defineComponent({
  name: "EventAttendeeList",
  components: {
    Button,
    AddEditAttendeeDialog,
    EventAttendAlert,
    StatusIcon,
    Header,
    TextButton,
    TextBody,
    AvatarCard,
    Subtitle,
    GuestModal,
    EventAttendeeListSection,
  },
  data: () => ({
    EVENT_STATUS: EVENT_STATUS,
    guestsIcon: mdiAccountMultipleOutline,
    tab: null,
  }),
  computed: {
    totalAttendeesCount() {
      return this.currentEvent?.attends_count ?? 0
    },
    RsvpReply() {
      return RsvpReply
    },
    eventId() {
      return this.$route.params.event_id
    },
    AttendingCoetanenSubtitle() {
      if (this.loadedCoetanenCount < 1) return null
      const component = ".coetanen_subtitle"
      return this.currentUserAttends
        ? this.tc$(component + ".attending", this.loadedCoetanenCount)
        : this.t$(component + ".other", [this.loadedCoetanenCount])
    },
    DeclinedMemberSubtitle() {
      if (this.declinedAttendeesCount < 1) return null
      return this.t$(".declined_subtitle", [this.declinedAttendeesCount])
    },
    declinedAttendeesCount() {
      return this.currentEvent?.declines_count ?? 0
    },
    AttendingMembersSubtitle() {
      if (this.otherAttendees < 1) return null
      const component = ".members_subtitle"
      const other =
        this.loadedCoetanenCount > 0
          ? this.tc$(
              component + ".other",
              this.totalAttendeesCount - this.loadedCoetanenCount
            )
          : ""
      return this.tc$(
        component + ".members",
        this.totalAttendeesCount - this.loadedCoetanenCount,
        {
          other: other,
        }
      )
    },
    noAttendeesText() {
      return this.t("no_attendees")
    },
    isCurrentMemberOrganizerOrAllowed() {
      const currentMember = this.currentMember
      const allowed = this.currentEvent?.links.update_url != null
      return this.isCurrentUserOrganizer(currentMember) || allowed
    },
    getSearchFunction() {
      if (
        this.currentEvent?.group.nonmember_access === "hidden" ||
        this.currentEvent?.nonmember_access === "hidden"
      ) {
        return this.buildQueryFunction(
          undefined,
          undefined,
          this.currentEvent?.group.id
        )
      } else {
        return this.buildQueryFunction(
          this.currentEvent?.group.client_id,
          this.currentEvent?.group.level_id
        )
      }
    },
    viewContext() {
      return {
        level_id: this.currentEvent?.level_id,
        group: this.currentEvent?.group,
        client_id: this.currentEvent?.client_id,
      }
    },
    ...mapState({
      guestCount: (state) => state.currentUserStatus.data.guests || 0,
      attendStatus: (state) => state.attendStatus,
      attendeesData: (state) => state.loadedEventAttendees.data,
      attendeesLoading: (state) => state.loadedEventAttendees.loading,
    }),
    ...mapGetters([
      "otherAttendeesCount",
      "loadedCoetanenCount",
      "coetanen",
      "otherAttendees",
      "currentUserAttends",
      "currentUserIsFirstAttendee",
      "guestsAllowed",
      "eventOrganizer",
      "isEventPublic",
      "currentClientId",
      "currentLevelId",
      "currentGroupId",
      "currentEvent",
      "isCurrentUserOrganizer",
      "declinedAttendees",
    ]),
    ...mapMemberGetters(["currentMember"]),
  },
  methods: {
    handleCreateAttendee(saveAttendeeObject) {
      const { attendee, response, guest_count } = saveAttendeeObject
      const personal_record_id = attendee.personal_record_id
      rsvpApi
        .createRsvp(this.eventId, personal_record_id, {
          reply: response,
          guests_count: guest_count,
        })
        .then((response) => {
          this.ADD_OPTIMISTIC_ATTENDEE(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleAttendeeEdit(saveAttendeeObject) {
      const { attendee, response, guest_count } = saveAttendeeObject
      const personal_record_id = attendee.personal_record_id
      rsvpApi
        .updateRsvp(this.eventId, personal_record_id, {
          reply: response,
          guests_count: guest_count,
        })
        .then(() => {
          this.$store.dispatch("event/getAttendees", this.eventId)
          this.updateAttendeeCounts(this.eventId)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    toggleAttending(isAttend) {
      if (isAttend) {
        let attendPayload = this.guestCount
          ? { event_id: this.eventId, guests_count: this.guestCount }
          : { event_id: this.eventId }
        this.addAttend(attendPayload)
      } else {
        this.addDecline(this.eventId)
      }
    },
    ...mapActions([
      "setAttendStatus",
      "addAttend",
      "addDecline",
      "updateAttendeeCounts",
    ]),
    ...mapMutations(["ADD_OPTIMISTIC_ATTENDEE"]),
  },
  setup() {
    const { t, t$, tc$ } = useTranslate("components.attendee_list")
    const buildQueryFunction = (clientId, levelId, groupId) => (query) =>
      membersApi
        .fuzzySearch(
          query,
          levelId ? levelId : undefined,
          clientId ? [clientId] : undefined,
          groupId ? [groupId] : undefined,
          true
        )
        .then((response) => response.data.results)

    const {
      loadMoreRSVP,
      isMoreRSVPAvailable,
      loadMoreDeclinedRSVP,
      isMoreDeclinedRSVPAvailable,
    } = useLoadMoreRSVPs(store.getters["event/currentEvent"])

    return {
      t,
      t$,
      tc$,
      buildQueryFunction,
      loadMoreRSVP,
      isMoreRSVPAvailable,
      loadMoreDeclinedRSVP,
      isMoreDeclinedRSVPAvailable,
    }
  },
})
</script>
