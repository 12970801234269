<template lang="pug">
portal.portal(selector=".event-form-portal")
  EventSheetButton.pa-1.px-sm-1.py-sm-0
    template(v-slot:activator="{openSheet}")
      div(:style="{ width: '100%' }")
        Button(
          :btnLabel="btnLabel",
          :block="isXsScreen",
          @click="openSheet"
        )
</template>

<script>
import EventForm from "../EventForm/EventForm.vue"
import { createNamespacedHelpers } from "vuex"
import Sheet from "components/UI/Sheet.vue"
import EventSheetButton from "components/Event/EventSheetButton.vue"
import Button from "components/UI/Button.vue"
const { mapGetters: mapMemberGetters, mapActions: mapMemberActions } =
  createNamespacedHelpers("member")
const { mapActions: mapGroupActions } = createNamespacedHelpers("group")
export default {
  name: "PortalEventForm",
  components: { EventSheetButton, Sheet, EventForm, Button },
  computed: {
    currentGroupIdFromRoute() {
      return this.$route.params.group_id || null
    },
    btnLabel() {
      return this.$t("components.event_form.new_event")
    },
    isXsScreen() {
      return this.$vuetify.breakpoint.name === "xs"
    },
  },
  methods: {
    ...mapMemberActions(["getCurrentMember"]),
    ...mapGroupActions(["getGroup"]),
  },
  async created() {
    await this.getCurrentMember()
    if (this.currentGroupIdFromRoute) {
      await this.getGroup(this.currentGroupIdFromRoute)
    }
  },
}
</script>
