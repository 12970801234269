import { __assign } from "tslib";
import { EVENT_STATUS } from "constants/constants";
import { createNamespacedHelpers } from "vuex";
var mapState = createNamespacedHelpers("event").mapState;
import { mdiAccountCircleOutline } from "@mdi/js";
import { mdiCheck } from "@mdi/js";
import { mdiClose } from "@mdi/js";
import ConfettiFlag from "../graphics/ConfettiFlag.vue";
import Subtitle from "../UI/Subtitle.vue";
import Header from "../UI/Header.vue";
import Button from "../UI/Button.vue";
import TextButton from "../UI/TextButton.vue";
import TextBody from "../UI/TextBody.vue";
import Hyperlink from "../UI/Hyperlink.vue";
import StatusIcon from "../UI/StatusIcon.vue";
import SamePageLink from "../UI/SamePageLink.vue";
import AvatarPlaceholder from "../UI/AvatarPlaceholder.vue";
import GuestModal from "./GuestModal.vue";
import Vue from "vue";
import "suus-api";
import "vue-i18n";
import EventPassedContent from "components/Event/event-card/EventPassedContent.vue";
export default Vue.extend({
    name: "EventCard",
    components: {
        EventPassedContent: EventPassedContent,
        ConfettiFlag: ConfettiFlag,
        Subtitle: Subtitle,
        Header: Header,
        TextButton: TextButton,
        TextBody: TextBody,
        Hyperlink: Hyperlink,
        StatusIcon: StatusIcon,
        SamePageLink: SamePageLink,
        AvatarPlaceholder: AvatarPlaceholder,
        Button: Button,
        GuestModal: GuestModal,
    },
    data: function () {
        return {
            showModal: false,
            EVENT_STATUS: EVENT_STATUS,
            userIcon: mdiAccountCircleOutline,
        };
    },
    methods: {
        deregister: function () {
            this.addDecline(this.eventId);
        },
        handleNoGuests: function () {
            this.addAttend(this.eventId, 0);
            this.setAttendStatus(EVENT_STATUS.FINISHED_REGISTRATION);
        },
        primaryClick: function () {
            var _a;
            if (this.cardStatus === EVENT_STATUS.REGISTER) {
                this.addAttend(this.eventId);
            }
            else if (this.cardStatus === EVENT_STATUS.REGISTERED) {
                this.showModal = true;
            }
            else if (this.cardStatus === EVENT_STATUS.DEREGISTERED) {
                //Abgemeldet --> Anmelden
                this.addAttend(this.eventId);
            }
            else if (this.cardStatus === EVENT_STATUS.FINISHED_REGISTRATION) {
                this.scrollToDescription();
            }
            else if (this.cardStatus === EVENT_STATUS.ATTEND_ONLINE_NOW) {
                window.open((_a = this.eventCallUrl) !== null && _a !== void 0 ? _a : undefined, "_blank");
            }
        },
        secondaryClick: function () {
            if (this.cardStatus === EVENT_STATUS.REGISTER) {
                //Abmelden
                this.setAttendStatus(EVENT_STATUS.DEREGISTERED);
                this.addDecline(this.eventId);
            }
            else if (this.cardStatus === EVENT_STATUS.ATTEND_ONLINE_NOW) {
                this.scrollToAttendeePanel();
            }
            else {
                this.handleNoGuests();
            }
        },
        isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
        },
        scrollToAttendeePanel: function () {
            this.$vuetify.goTo("#suus__event__attendee-list", {
                duration: 300,
                offset: 0,
                easing: "easeInOutCubic",
            });
            this.addActivePanel(1);
        },
        scrollToDescription: function () {
            this.$vuetify.goTo("#suus__event__description", {
                duration: 300,
                offset: 0,
                easing: "easeInOutCubic",
            });
            this.addActivePanel(0);
        },
        setAttendStatus: function (status) {
            this.$store.dispatch("event/setAttendStatus", status);
        },
        addDecline: function (eventId) {
            this.$store.dispatch("event/addDecline", eventId);
        },
        addAttend: function (event_id, guests_count) {
            this.$store.dispatch("event/addAttend", { event_id: event_id, guests_count: guests_count });
        },
        addActivePanel: function (panelIndex) {
            this.$store.commit("event/addActivePanel", panelIndex);
        },
    },
    computed: __assign(__assign({ cardOptions: function () {
            return {
                register: {
                    headerText: this.$i18n.t("components.event_card.card_options.register.headerText"),
                    primaryBtnLabel: this.$i18n.t("components.event_card.card_options.register.primaryBtnLabel"),
                    secondaryBtnLabel: this.$i18n.t("components.event_card.card_options.register.secondaryBtnLabel"),
                },
                registered: {
                    headerText: this.$i18n.t("components.event_card.card_options.registered.headerText"),
                    primaryBtnLabel: this.$i18n.t("components.event_card.card_options.registered.primaryBtnLabel"),
                    secondaryBtnLabel: this.$i18n.t("components.event_card.card_options.registered.secondaryBtnLabel"),
                    icon: mdiCheck,
                },
                deregistered: {
                    headerText: this.$i18n.t("components.event_card.card_options.deregistered.headerText"),
                    primaryBtnLabel: this.$i18n.t("components.event_card.card_options.deregistered.primaryBtnLabel"),
                    icon: mdiClose,
                },
                finished_registration: {
                    headerText: this.$i18n.t("components.event_card.card_options.finished_registration.headerText"),
                    primaryBtnLabel: this.$i18n.t("components.event_card.card_options.finished_registration.primaryBtnLabel"),
                    icon: mdiCheck,
                },
                attend_online_now: {
                    headerText: this.$i18n.t("components.event_card.card_options.attend_online_now.headerText"),
                    primaryBtnLabel: this.$i18n.t("components.event_card.card_options.attend_online_now.primaryBtnLabel"),
                    secondaryBtnLabel: this.$i18n.t("components.event_card.card_options.attend_online_now.secondaryBtnLabel"),
                    icon: mdiCheck,
                },
                event_passed: {
                    headerText: this.$i18n.t("components.event_card.card_options.event_passed.headerText"),
                },
            };
        }, modalWidth: function () {
            // TODO: Refactor into mixin, also used in EventAttendeeList
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 600;
                case "sm":
                    return 800;
                case "md":
                    return 600;
                default:
                    return 800;
            }
        }, eventId: function () {
            return this.$route.params.event_id;
        }, eventCallUrl: function () {
            return this.eventData.custom_call_url;
        }, isAttending: function () {
            return (this.cardStatus === EVENT_STATUS.REGISTERED ||
                this.cardStatus === EVENT_STATUS.FINISHED_REGISTRATION);
        }, confettiFlagClasses: function () {
            return [
                "suus__grapic d-none d-sm-flex",
                "".concat((this.cardStatus === EVENT_STATUS.DEREGISTERED ||
                    this.cardStatus === EVENT_STATUS.EVENT_PASSED) &&
                    "disabled"),
            ];
        }, headerCustomClass: function () {
            return this.isAttending && !this.loading ? "mb-0" : "mb-9";
        }, showOtherAttendeesInsteadOfCoetans: function () {
            return !!(this.otherAttendees &&
                this.otherAttendees.length &&
                ((this.loadedCoetanenCount <= 0 && !this.currentUserAttends) ||
                    (this.loadedCoetanenCount === 1 && this.currentUserAttends)));
        }, noAttendeesText: function () {
            return !this.hasAttendees
                ? this.$t("components.event_card.no_attendees_text")
                : null;
        }, noAttendeesDeRegisteredText: function () {
            return this.$t("components.event_card.deregistered_text", [
                this.currentUserName ? ", ".concat(this.currentUserName) : "",
            ]);
        }, teaserAttendees: function () {
            if (!this.showOtherAttendeesInsteadOfCoetans &&
                this.coetanen &&
                this.coetanen.length) {
                return this.coetanen.slice(0, 3);
            }
            if (this.otherAttendees && this.otherAttendees.length) {
                return this.otherAttendees.slice(0, 3);
            }
            return null;
        }, teaserAttendeesSubtitle: function () {
            var _a;
            if (this.currentUserIsFirstAttendee) {
                var user = ((_a = this.currentUserAttends) === null || _a === void 0 ? void 0 : _a.name)
                    ? ", ".concat(this.currentUserAttends.name)
                    : "";
                return this.$t("components.event_card.attendees_subtitle.user_is_first", [user]);
            }
            if (this.currentUserAttends && !this.showOtherAttendeesInsteadOfCoetans) {
                return this.loadedCoetanenCount <= 2
                    ? this.$t("components.event_card.attendees_subtitle.one_coetan_attends")
                    : this.$t("components.event_card.attendees_subtitle.more_coetans_attend", [this.loadedCoetanenCount - 1]);
            }
            if (!this.showOtherAttendeesInsteadOfCoetans) {
                return this.loadedCoetanenCount === 1
                    ? this.$t("components.event_card.attendees_subtitle.one_coetan_attends")
                    : this.$t("components.event_card.attendees_subtitle.more_coetans_attend", [this.loadedCoetanenCount]);
            }
            if (this.totalAttendeesCount > 0) {
                return this.totalAttendeesCount === 1
                    ? this.$t("components.event_card.attendees_subtitle.one_club_member_attends")
                    : this.$t("components.event_card.attendees_subtitle.more_club_members_attends", [this.totalAttendeesCount]);
            }
            return null;
        }, teaserAttendeesText: function () {
            if (!this.teaserAttendees)
                return "";
            var names = this.teaserAttendees.map(function (el) {
                return el.name;
            });
            var dots = names.length < 3 ? "" : "... ";
            if (names.length === 1) {
                return names.join("");
            }
            if (names.length === 2) {
                return names.join(" und ");
            }
            if (this.$vuetify.breakpoint.xs) {
                return names.join(", ").substr(0, 35) + dots;
            }
            if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg) {
                return names.join(", ").substr(0, 66) + dots;
            }
            return names.join(", ") + dots;
        }, teaserOtherAttendeesSubtitle: function () {
            if (!this.showOtherAttendeesInsteadOfCoetans &&
                this.otherAttendeesCount > 0) {
                return this.$t("components.event_card.other_attendees_subtitle", [
                    this.otherAttendeesCount,
                ]);
            }
            return null;
        }, teaserOtherGuestsSubtitle: function () {
            if (this.totalGuestsCount > 0) {
                return this.totalGuestsCount === 1
                    ? this.$t("components.event_card.other_guests_subtitle.one")
                    : this.$t("components.event_card.other_guests_subtitle.more", [
                        this.totalGuestsCount,
                    ]);
            }
            return null;
        }, headerText: function () {
            if (this.cardOptions[this.cardStatus].headerText) {
                return this.cardOptions[this.cardStatus].headerText || null;
            }
            return null;
        }, primaryBtnLabel: function () {
            if (this.cardOptions[this.cardStatus].primaryBtnLabel) {
                return this.cardOptions[this.cardStatus].primaryBtnLabel || null;
            }
            return null;
        }, secondaryBtnLabel: function () {
            if (this.cardOptions[this.cardStatus].secondaryBtnLabel) {
                return this.cardOptions[this.cardStatus].secondaryBtnLabel || null;
            }
            return null;
        }, btnColor: function () {
            return this.cardStatus === EVENT_STATUS.ATTEND_ONLINE_NOW
                ? "highlight"
                : "primary";
        }, showGuestsText: function () {
            return !!(this.eventData &&
                this.eventData.allow_guests &&
                this.cardStatus === EVENT_STATUS.FINISHED_REGISTRATION);
        }, guestsText: function () {
            if (this.showGuestsText) {
                if (this.ownGuestCount > 0) {
                    return this.$t("components.event_card.guests_text.your_guests", [
                        this.ownGuestCount,
                    ]);
                }
                return this.$t("components.event_card.guests_text.no_guests");
            }
            return null;
        }, icon: function () {
            if (this.cardOptions[this.cardStatus].icon) {
                return this.cardOptions[this.cardStatus].icon || null;
            }
            return null;
        }, graphicWidth: function () {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "180";
                case "sm":
                    return "100%";
                default:
                    return "250";
            }
        }, skeletonLoader: function () {
            return {
                type: "sentences, list-item-avatar-three-line, list-item",
            };
        } }, mapState({
        eventData: function (state) { return state.currentEvent.data; },
        eventLoading: function (state) { return state.currentEvent.loading; },
        ownGuestCount: function (state) { var _a; return (((_a = state.currentUserStatus.data) === null || _a === void 0 ? void 0 : _a.guests) || 0); },
        cardStatus: function (state) { return state.attendStatus; },
        allow_guests: function (state) {
            return (state.currentEvent.data &&
                state.currentEvent.data.allow_guests);
        },
        currentUserStatus: function (state) {
            return state.currentUserStatus.data;
        },
        currentUserStatusLoading: function (state) {
            return state.currentUserStatus.loading;
        },
    })), { totalAttendeesCount: function () {
            return (this.$store.getters["event/totalAttendeesCount"] -
                this.loadedCoetanenCount);
        }, totalGuestsCount: function () {
            return this.$store.getters["event/totalGuestsCount"];
        }, otherAttendeesCount: function () {
            return this.$store.getters["event/otherAttendeesCount"];
        }, loadedCoetanenCount: function () {
            return this.$store.getters["event/loadedCoetanenCount"];
        }, coetanen: function () {
            return this.$store.getters["event/coetanen"];
        }, otherAttendees: function () {
            return this.$store.getters["event/otherAttendees"];
        }, currentUserIsFirstAttendee: function () {
            return this.$store.getters["event/currentUserIsFirstAttendee"];
        }, currentUserAttends: function () {
            return this.$store.getters["event/currentUserAttends"];
        }, hasAttendees: function () {
            return this.$store.getters["event/hasAttendees"];
        }, loading: function () {
            return this.$store.getters["event/loading"];
        }, currentUserName: function () {
            return this.$store.getters["event/currentUserName"];
        } }),
});
