import { __assign } from "tslib";
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
var _a = createNamespacedHelpers("event"), mapState = _a.mapState, mapGetters = _a.mapGetters;
import { mdiCalendarRange } from "@mdi/js";
import { mdiMapMarkerOutline } from "@mdi/js";
import { mdiShieldAccountOutline } from "@mdi/js";
import { mdiAccountMultipleOutline } from "@mdi/js";
import OnlineEventIcon from "../icons/OnlineEventIcon.vue";
import Hyperlink from "../UI/Hyperlink.vue";
import "suus-api";
import { formatToDateSpanLong } from "app/util/date.helper";
export default Vue.extend({
    name: "EventDetails",
    components: { OnlineEventIcon: OnlineEventIcon, Hyperlink: Hyperlink },
    data: function () {
        return {
            calenderIcon: mdiCalendarRange,
            placeIcon: mdiMapMarkerOutline,
            organiserIcon: mdiShieldAccountOutline,
            accessIcon: mdiAccountMultipleOutline,
            dateSeparator: " - ",
        };
    },
    computed: __assign(__assign({ eventId: function () {
            return this.$route.params.event_id;
        }, eventCallUrl: function () {
            return this.eventData.custom_call_url;
        }, eventContactName: function () {
            return this.eventContact && this.eventContact.name;
        }, eventContactUrl: function () {
            return this.eventContact && this.eventContact.url;
        }, eventCircleOfPartcipantsName: function () {
            return this.eventCircleOfPartcipants && this.eventCircleOfPartcipants.name;
        }, eventCircleOfPartcipantsUrl: function () {
            return this.eventCircleOfPartcipants && this.eventCircleOfPartcipants.url;
        }, location: function () {
            return this.eventData && this.eventData.location;
        }, online: function () {
            return this.eventData && this.eventData.online;
        }, showEndDate: function () {
            if (this.eventData &&
                this.eventData.end_at &&
                this.eventData.end_at !== this.eventData.start_at) {
                return true;
            }
            return false;
        }, eventStartDate: function () {
            if (this.eventData && this.eventData.start_at) {
                return this.eventData.start_at;
            }
            return null;
        }, eventEndDate: function () {
            if (this.showEndDate && this.eventData && this.eventData.end_at) {
                return this.eventData.end_at;
            }
            return null;
        }, eventStartTime: function () {
            if (this.eventData && this.eventData.all_day) {
                return null;
            }
            else if (this.eventData && this.eventData.start_at) {
                return this.eventData.start_at;
            }
            else {
                return null;
            }
        }, eventEndTime: function () {
            if (this.eventData && this.eventData.all_day) {
                return null;
            }
            else if (this.eventData && this.eventData.end_at) {
                return this.eventData.end_at;
            }
            else {
                return null;
            }
        }, eventAccess: function () {
            var _a;
            switch ((_a = this.eventData) === null || _a === void 0 ? void 0 : _a.nonmember_access) {
                case "write":
                    return "Offen";
                case "read":
                    return "Intern";
                case "hidden":
                    return "Geheim";
            }
        } }, mapState({
        eventData: function (state) { return state.currentEvent.data; },
        eventError: function (state) { return state.currentEvent.error; },
    })), mapGetters(["eventContact", "eventCircleOfPartcipants", "loading"])),
    setup: function () {
        return {
            formatToDateSpanLong: formatToDateSpanLong,
        };
    },
});
