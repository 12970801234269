import { computed, defineComponent, inject } from "vue";
import "suus-api";
import AttachmentListItemContent from "./AttachmentListItemContent.vue";
import AttachmentThumbnail from "features/attachments/AttachmentThumbnail.vue";
import "features/gallery/lightbox/lightbox.service";
import { mdiDownload } from "@mdi/js";
import { download } from "app/util/download.helper";
import { VIcon } from "vuetify/lib/components";
import { isMimeTypeSupported } from "app/modules/common/supported-image-formats";
export default defineComponent({
    name: "AttachmentsListItem",
    components: { AttachmentThumbnail: AttachmentThumbnail, AttachmentListItemContent: AttachmentListItemContent, VIcon: VIcon },
    props: {
        attachment: {
            type: Object,
            required: true,
        },
    },
    setup: function (props) {
        var attachment = computed(function () { return props.attachment; });
        var href = computed(function () {
            return isSupportedImage.value ? undefined : attachment.value.links.download_url;
        });
        var lightboxService = inject("lightboxService");
        var clickHandler = computed(function () {
            if (isSupportedImage.value) {
                return function () {
                    var _a;
                    return lightboxService.openLightbox((_a = attachment.value.links.original_url) !== null && _a !== void 0 ? _a : attachment.value.links.download_url);
                };
            }
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return function () { };
        });
        var handleDownload = function () {
            var _a;
            download(attachment.value.links.original_url, (_a = attachment.value.name) !== null && _a !== void 0 ? _a : "Anhang");
        };
        var isSupportedImage = computed(function () {
            var _a;
            return isMimeTypeSupported((_a = attachment.value.content_type) !== null && _a !== void 0 ? _a : attachment.value.type);
        });
        var thumbnailUrlIfSupported = computed(function () {
            if (isSupportedImage.value) {
                return attachment.value.links.icon_url;
            }
            return undefined;
        });
        return {
            href: href,
            clickHandler: clickHandler,
            mdiDownload: mdiDownload,
            handleDownload: handleDownload,
            thumbnailUrlIfSupported: thumbnailUrlIfSupported,
        };
    },
});
