import Vue from "vue";
import { mdiCloseCircle } from "@mdi/js";
import FieldLabel from "../UI/FieldLabel.vue";
export default Vue.extend({
    name: "ChipSelectCombo",
    components: { FieldLabel: FieldLabel },
    props: {
        options: {
            type: Array,
            required: false,
            default: function () {
                return [];
            },
        },
        value: {
            type: Array,
            required: false,
            default: function () {
                return [];
            },
        },
        label: { type: String, required: true },
        noDataHTMLText: {
            type: String,
            default: "Klicke auf <kbd>enter</kbd> um eine neue Option zu erstellen.",
        },
        mandatory: { type: Boolean, default: false },
    },
    data: function () {
        return {
            closeIcon: mdiCloseCircle,
            menu: false,
            model: this.value,
            search: null,
        };
    },
    computed: {
        noDataFoundForSearch: function () {
            return "Keine Ergebnissse f\u00FCr <strong>".concat(this.search, "</strong>. ").concat(this.noDataHTMLText);
        },
    },
    watch: {
        value: function (val) {
            this.model = val;
        },
        model: function (val, prev) {
            if (val.length === prev.length)
                return;
            this.model = val;
            this.$emit("input", val);
        },
        search: function (val, prev) {
            if (val === prev)
                return;
            this.$emit("search", val);
        },
    },
});
