import { computed, defineComponent } from "vue";
import theme from "constants/theme";
export default defineComponent({
    name: "SuusTextField",
    props: {
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        buttonText: {
            type: String,
            required: false,
            default: null,
        },
        value: {
            type: String,
            required: false,
            default: "",
        },
        autofocus: {
            type: Boolean,
            required: false,
            default: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        labelStyles: function (disabled) {
            if (disabled === void 0) { disabled = false; }
            return {
                color: this.primaryColor,
                fontWeight: "400",
                opacity: disabled ? "0.5" : "1.0",
            };
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var primaryColor = theme.colors.light.primary;
        var textFieldValue = computed({
            get: function () { return props.value; },
            set: function (value) { return emit("input", value); },
        });
        return {
            textFieldValue: textFieldValue,
            primaryColor: primaryColor,
        };
    },
});
