<template lang="pug">
.ui.image(v-if="thumb")
  img(:src="thumb")
sui-icon(v-else :size="size" :name="icon")
</template>

<script>
export default {
  name: "InodeIcon",
  props: {
    inode: { type: Object, required: true },
    size: { type: String, default: "huge" },
  },
  computed: {
    icon() {
      if (!this.inode.icon || !this.inode.icon.url) {
        if (
          this.inode.content_type &&
          this.inode.content_type.startsWith("video/")
        )
          return "suus-videocamera-71"

        switch (this.inode.type) {
          case "Container":
            return "suus-single-copies"
          case "Folder":
            return "suus-folder-13"
          case "Gallery":
            return "suus-folder-image"
          default:
            return "suus-single-folded-content"
        }
      }
    },
    thumb() {
      return this.inode.icon ? this.inode.icon.url : null
    },
  },
}
</script>

<style scoped></style>
