/*
This concern is a temporary glue for loading more RSVPs. Right now most of the logic for loading RSVPs is in the vuex store.
When there is time for a refactor, the vuex store logic should be removed and this class should be abstracted for generic use.
 */
import { __awaiter, __generator } from "tslib";
import { computed, ref } from "vue";
import store from "../../../../store";
import { rsvpApi } from "store/api";
import "suus-api";
export default function useLoadMoreRSVPs(event) {
    var eventId = event.id.toString();
    var attends_count = event.attends_count;
    var declines_count = event.declines_count;
    var limit = 12;
    var loadedRSVPPage = ref(1);
    var offsetRSVP = computed(function () { return attendeesCount.value + optimisticCount.value; });
    var isMoreRSVPAvailable = computed(function () { return store.getters["event/isMoreRSVPsAvailable"]; });
    var attendeesCount = computed(function () { return store.getters["event/currentAttendeesCount"]; });
    var declinesCount = computed(function () { return store.getters["event/loadedDeclinesCount"]; });
    var optimisticCount = computed(function () { return store.getters["event/optimisticAttendeesCount"]; });
    function loadMoreRSVP() {
        return __awaiter(this, void 0, void 0, function () {
            var rsvpResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isMoreRSVPAvailable.value) return [3 /*break*/, 2];
                        loadedRSVPPage.value = loadedRSVPPage.value + 1;
                        return [4 /*yield*/, rsvpApi.getRsvps(eventId, offsetRSVP.value, limit, undefined, "attend")];
                    case 1:
                        rsvpResponse = _a.sent();
                        store.commit("event/ADD_CURRENT_ATTENDEES", rsvpResponse.data);
                        store.commit("event/SET_ATTENDEES_ALL_LOADED", rsvpResponse.status === 204 ||
                            rsvpResponse.data.length < limit ||
                            attendeesCount.value >= attends_count + optimisticCount.value);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    var loadedDeclinedRSVPPage = ref(1);
    var offsetDeclinedRSVP = computed(function () { return declinesCount.value; });
    var isMoreDeclinedRSVPAvailable = computed(function () { return store.getters["event/isMoreDeclinedRSVPsAvailable"]; });
    function loadMoreDeclinedRSVP() {
        return __awaiter(this, void 0, void 0, function () {
            var rsvpResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isMoreDeclinedRSVPAvailable.value) return [3 /*break*/, 2];
                        loadedDeclinedRSVPPage.value = loadedDeclinedRSVPPage.value + 1;
                        return [4 /*yield*/, rsvpApi.getRsvps(eventId, offsetDeclinedRSVP.value, limit, undefined, "decline")];
                    case 1:
                        rsvpResponse = _a.sent();
                        store.commit("event/ADD_CURRENT_DECLINED_ATTENDEES", rsvpResponse.data);
                        store.commit("event/SET_DECLINED_ATTENDEES_ALL_LOADED", rsvpResponse.status === 204 ||
                            rsvpResponse.data.length < limit ||
                            declinesCount.value >= declines_count + optimisticCount.value);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    return {
        loadMoreRSVP: loadMoreRSVP,
        isMoreRSVPAvailable: isMoreRSVPAvailable,
        loadMoreDeclinedRSVP: loadMoreDeclinedRSVP,
        isMoreDeclinedRSVPAvailable: isMoreDeclinedRSVPAvailable,
    };
}
